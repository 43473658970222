import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-sidepot-entries',
  templateUrl: './sidepot-entries.component.html',
  styleUrls: ['./sidepot-entries.component.scss']
})
export class SidepotEntriesComponent implements OnInit {

  eventId: any;
  sidepotId: any;
  eventData: any = {};
  sidePotData: any = {};
  entries: any = [];

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  
  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }


  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.sidepotId = routeParams.get('sidepotid');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.event.getOneSidePot(this.sidepotId).subscribe(
          response => {

            this.sidePotData = response;

            this.entry.sidepot(this.sidepotId).subscribe(
              response => {
                this.entries = response;
              },
              err => console.log(err)
            );

          },
          err => console.log(err)
        );




      },
      err => console.log(err)
    );


  }

  exportToCSV() {

    var str = '';
    var header = "Rider,Horse,Entry Date";

    //Add header to output str
    str += header;
    str += '\n';

    for (var i = 0; i < this.entries.length; i++) {
      var line = '';
      if (line != '') line += ','

      if (this.entries[i]['entry']?.name) {
        line += this.entries[i]['entry']['name'].replace(/,/g, '');;
      } else {
        line += "";
      }
      
      line += ','
      if (this.entries[i]['name']) {
        line += this.entries[i]['name'].replace(/,/g, '');;
      } else {
        line += "";
      }

      line += ','
      if (this.entries[i]['entry']?.createdAt) {
        line += this.entries[i]['entry']['createdAt'].replace(/,/g, '');;
      } else {
        line += "";
      }

  
      str += line + '\n';
    }



    // const csvArray = header.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.sidePotData['name'] + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }



}
