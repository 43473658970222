import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {


  eventId: any;
  eventData: any = {};

  files: File[] = [];
  uploadProgress: any = 0;

  viewSideMenu: any = false;
  eventForm: FormGroup;

  config: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY'
  }

  dateTimeconfig: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY HH:mm:ss'
  }

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  constructor(private _formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) {
    this.eventForm = this._formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
      openingDateTime: new FormControl('', Validators.required),
      closingDateTime: new FormControl('', Validators.required),
      complete: ['', Validators.required],
      paidEvent: ['', Validators.required],
      hybridEvent: ['', Validators.required],
      bbrBarrel: ['', Validators.required],
      rodeo: ['', Validators.required],
      showStateOnContestant: ['', Validators.required],
      eventExtraText: [''],
      emailFooterText: [''],
      cashOrCheckText: ['']
    });
  }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.event.getOne(this.eventId).subscribe(
      response => {

        console.log(response);

        this.eventForm.patchValue({
          name: response['name'],
          address: response['address'],
          start: response['start'],
          end: response['end'],
          openingDateTime: moment(response['openingDateTime']).local().format('MM-DD-YYYY HH:mm:ss'),
          closingDateTime: moment(response['closingDateTime']).local().format('MM-DD-YYYY HH:mm:ss'),
          complete: response['complete'],
          paidEvent: response['paidEvent'],
          hybridEvent: response['hybridEvent'],
          bbrBarrel: response['bbrBarrel'],
          showStateOnContestant: response['showStateOnContestant'],
          rodeo: response['rodeo'],
          eventExtraText: response['eventExtraText'],
          emailFooterText: response['emailFooterText'],
          cashOrCheckText: response['cashOrCheckText']
        })

      },
      err => console.log(err)
    );

  }

  onSubmit(): void {

    console.log(this.eventForm.value);
    let momentDateOpening = moment(this.eventForm.value['openingDateTime']);
    let utcOpeningDateTime = moment.utc(momentDateOpening).format();

    this.eventForm.value['openingDateTime'] = utcOpeningDateTime;

    let momentDateClosing = moment(this.eventForm.value['closingDateTime']);
    let utcClosingDateTime = moment.utc(momentDateClosing).format();

    this.eventForm.value['closingDateTime'] = utcClosingDateTime;


    this.event.edit(this.eventId, this.eventForm.value).subscribe(
      response => {

        this.router.navigate(['events']);

      },
      err => console.log(err)
    );


  }

  delete() {
    this.event.remove(this.eventId).subscribe(
      response => {

        this.router.navigate(['events']);

      },
      err => console.log(err)
    );
  }

  duplicateEvent() {
    this.event.duplicate(this.eventId).subscribe(
      response => {

        this.router.navigate(['events']);

      },
      err => console.log(err)
    );
  }



  onSelect(event: any) {
    console.log(event);
    this.files = event.addedFiles;
    this.uploadLogo();
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


  uploadLogo() {

    console.log(this.files);
    if (this.files.length) {

      var fd = new FormData(document.forms[0]);

      fd.append("logo", this.files[0]);

      console.log(fd);

      this.event.uploadLogo(fd, this.eventId).subscribe((event: any) => {

        if (event['status'] == 'progress') {
          this.uploadProgress = event['message'];
        } else if (event['success'] == true) {


          this.uploadProgress = 0;
          this.files = [];

        }

      });

    }


  }

}
