import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {

  eventId: any;
  feeArr: any = [];

  
  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.fees(this.eventId).subscribe(
      response => {
        this.feeArr = response;
      },
      err => console.log(err)
    );
  }


  createFee() {
    this.router.navigate(['create-fee', this.eventId]);
  }

  deleteFee(id: any) {
    console.log('Trying to delete ');
    this.event.deleteFee(id).subscribe(
      response => {
        this.router.navigate(['fees']);
      },
      err => console.log(err)
    );
  }
}
