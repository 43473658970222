import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'admin-sidepots',
  templateUrl: './sidepots.component.html',
  styleUrls: ['./sidepots.component.scss']
})
export class SidepotsComponent implements OnInit {

  eventId: any;
  classArr: any = [];
  sidepotArr: any = [];
  sidepotEntries: any = [];
  view: any = 0;

  switchView(int: any) {
    this.view = int;
  }

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }


  constructor(public excelService: ExcelService, private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getClasses(this.eventId).subscribe(
      response => {
        this.classArr = response;
        console.log(this.classArr);
      },
      err => console.log(err)
    );


    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {
        console.log("All Sidepot Entrys", response);
        this.sidepotEntries = response;
      },
      err => console.log(err)
    );
  }


  downloadAllSidePotEntrys() {

    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {
        console.log("All Sidepot Entrys", response);
      },
      err => console.log(err)
    );

  }

  getSidePotName(id: any) {
    let name = "";

    for (let i = 0; i < this.classArr.length; i++) {

      for (let x = 0; x < this.classArr[i]['sidePots'].length; x++) {
        let sidepot = this.classArr[i]['sidePots'][x];
        if (sidepot['_id'] === id) {
          name = sidepot['name'];
        }

      }
    }

    return name;
  }

  deleteItem(id: any) {
    this.entry.removesidepotentry(id).subscribe(
      response => {
        console.log("Sidepot entry has been deleted", response);

        this.entry.getAllSidePotEntrys(this.eventId).subscribe(
          response => {
            console.log("All Sidepot Entrys", response);
            this.sidepotEntries = response;
          },
          err => console.log(err)
        );
      },
      err => console.log(err)
    );
  }

}
