import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})

export class EventComponent implements OnInit {

  eventData: any = {};
  stats: any = {};
  recentEntryArr: any = [];
  sidepotArr: any = [];
  eventId: any;
  eventForm: FormGroup;
  private gridApi!: GridApi<any>;

  currencyFormatter(params: ValueFormatterParams) {
    return '$' + (params.value / 100).toString();
  }

  columnDefs: ColDef[] = [
    { field: 'name' },
    { headerName:'Amount', field: 'amountPaid', valueFormatter:  this.currencyFormatter},
    { field: 'city' },
    { field: 'state' }
  ];
   

  collectedEntries: any = [];

  constructor(public auth: AuthService, private _formBuilder: FormBuilder, private event: EventService, private entry: EntryService, private route: ActivatedRoute, private router: Router) {
    this.eventForm = this._formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.eventForm.patchValue({
          name: response['name'],
          address: response['address'],
          start: response['start'],
          end: response['end']
        })

        this.entry.recent(this.eventId).subscribe(
          response => {
            console.log(response);
            this.recentEntryArr = response;
          },
          err => console.log(err)
        );

        this.entry.all(this.eventId).subscribe(
          response => {
            this.collectedEntries = response;
          },
          err => console.log(err)
        );


      },
      err => console.log(err)
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onFilterTextBoxChanged($event: any) {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  onSelectionChanged($event: any) {
    const selectedRows = this.gridApi.getSelectedRows();
    console.log(selectedRows);
    this.router.navigate(['/events', this.eventId, 'entries', selectedRows[0]['_id']]);
  }

  openShowtime() {
    this.router.navigate(['showtime', this.eventId]);
  }

  openEditForm() {
    window.open("https://event.myentryform.com/edit/" + this.eventData['_id'], "_blank");
  }

  newEntry() {
    window.open("https://entry.myentryform.com/admin/" + this.eventData['_id'], "_blank");
  }






}


