<dl class="divide-y divide-gray-200 text-sm lg:col-span-5">
  <div class="py-4 sm:ml-6">
      <h3 class="text-base font-medium text-gray-900">
          Total Income
      </h3>
  </div>
  <div class="pt-6 pb-4 flex items-center justify-between">
    <dt class="text-gray-600">Subtotal</dt>
    <dd class="font-medium text-gray-900">{{income.eventTotalIncomeData.totalCollected / 100 | currency}}</dd>
</div>
<div class="py-4 flex items-center justify-between">
    <dt class="text-gray-600">Service Fee</dt>
    <dd class="font-medium text-gray-900">{{income.eventTotalIncomeData.barrelbookFee / 100 | currency}}</dd>
</div>
<div class="py-4 flex items-center justify-between">
    <dt class="text-gray-600">Proccesing Fee</dt>
    <dd class="font-medium text-gray-900">{{income.eventTotalIncomeData.stripeFees / 100 | currency}}</dd>
</div>
<div class="pt-4 flex items-center justify-between">
    <dt class="font-medium text-gray-900">Total</dt>
    <dd class="font-medium text-emerald-600">{{income.eventTotalIncomeData.accountBalance / 100 | currency}}</dd>
</div>
</dl>
