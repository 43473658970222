import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  eventId: any;
  entries: any = [];
  eventData: any = {};

  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.entry.getAllNotes(this.eventId).subscribe(
          response => {
            this.entries = response;
          },
          err => console.log(err)
        );


      },
      err => console.log(err)
    );

  }

}
