import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DrawService {

  constructor(private http: HttpClient) { }

  updateRunOrder(classId: any, drawDate: any, data: any) {
    return this.http.put<Event>(environment.apiUrl + 'draw/runOrder/' + classId + '/' + drawDate, data);
  }


  exportDraw(eventId: any) {
    return this.http.get<any>(environment.apiUrl + 'draw/export/' + eventId);
  }

  resetRunOrder(classId: any, drawDate: any) {
    return this.http.delete<Event>(environment.apiUrl + 'draw/runOrder/' + classId + '/' + drawDate);
  }

}
