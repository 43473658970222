import { HttpClient, HttpEventType } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { EventClass } from '../interfaces/EventClass';
import { Event } from '../interfaces/event';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(private http: HttpClient) { }

  public company: any[] = [];

  getAll() {
    return this.http.get<Event[]>(environment.apiUrl + 'company/');
  }

  add(data: any) {
    return this.http.post<any>(environment.apiUrl + 'company/', data);
  }


}
