<dl class="divide-y divide-gray-200 text-sm lg:col-span-5 py-4 bg-white">

<div class="bg-white px-4 py-5 sm:px-6">
  <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
    <div class="ml-4 mt-2">
      <h3 class="text-sm font-semibold text-gray-900">Classes</h3>
      <p class="mt-1 text-sm text-gray-500">Sidepots listed after clicking toggle</p>
    </div>
  </div>
</div>

<div *ngFor="let class of classArr;">
  <div class="bg-white px-4 py-2 sm:px-6 hover:bg-gray-100 relative">
    <h3 [routerLink]="['/events/class', eventId, class['_id']]" class="text-md leading-6 font-medium text-gray-900">
      {{class['name']}}
    </h3>

    <button *ngIf="class['sidePots'].length > 0" (click)="class['show'] = !class['show']" type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>
  <ul *ngIf="class['show']" class="divide-y divide-gray-200">
    <li *ngFor="let item of class['sidePots'];">
      <a [routerLink]="['/events/sidepot', eventId, item['_id']]" class="block hover:bg-gray-50">
        <div class="flex items-center px-4 py-2 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">

            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
              <div>
                <p class="text-sm text-emerald-600 truncate">{{item['name']}}</p>
              </div>
            </div>
          </div>
          <div>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
    </li>

  </ul>

</div>
</dl>