import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.scss']
})
export class SuperuserComponent implements OnInit {
  
  companyArr: any = [];

  view: any = 0;
  currentUser: any;
  
  private gridApi!: GridApi<any>;
  
  columnDefs: ColDef[] = [
    { colId: 'name', field: 'name', resizable: true, minWidth: 300, cellClass: 'bold'},
    { field: 'phone', headerName: 'Phone #', minWidth: 200 },
    { field: '_id', headerName: 'Object Id', minWidth: 200 }
  ];

  gridOptions: any = {
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
      columnLimits: [
          {
              colId: 'name',
              minWidth: 300
          }
      ]
  },
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }


  onSelectionChanged($event: any) {
    const selectedRows = this.gridApi.getSelectedRows();
    this.auth.changeCurrentCompany(selectedRows[0]['_id'])
    this.router.navigate(['events']);

  }

  
  onFilterTextBoxChanged($event: any) {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  
  constructor(private auth: AuthService, private router: Router, private company: CompanyService) { }

  ngOnInit(): void {
    this.auth.user.subscribe(x => (this.currentUser = x));

    this.company.getAll().subscribe(
      response => {
       this.companyArr = response;
       console.log(this.companyArr);
      },
      err => console.log(err)
    );
    
  }

  selectView(int: any) {
    this.view = int;
  } 

  openCompany(data: any) {
    this.auth.changeCurrentCompany(data['_id'])
    this.router.navigate(['events']);
  }

  createOrg() {
    this.router.navigate(['create-org']);
  }


}

