<div class="bg-gray-100">
  <admin-header [title]="{title: 'Events'}" [hasSecondButton]="{value: true, secondButtonText: 'Create New Event'}"
    (secondButtonClicked)="openCreateEvent()"></admin-header>



  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">



        <div class="col-span-2 bg-white overflow-hidden shadow rounded-lg">
          <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div class="flex flex-wrap items-center justify-center sm:flex-nowrap">
          
              <form class="w-full">
                <div class="flex gap-x-4">
                  <div class="mx-auto w-auto">
                    <label for="desktop-search" class="sr-only">Search</label>
                    <div class="relative text-gray-400 focus-within:text-gray-600">
                      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <!-- Heroicon name: mini/magnifying-glass -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                          fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clip-rule="evenodd" />
                        </svg>
                      </div>
                      <input
                        class="block w-full rounded-md border border-gray-200 bg-white py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600 sm:text-sm"
                        placeholder="Search" type="search" name="search"  id="filter-text-box" (input)="onFilterTextBoxChanged($event)">
                    </div>
                  </div>
                
                </div>
              </form>

             

            </div>
          </div>

          <ag-grid-angular style="width: auto; height: 500px;" class="ag-theme-material" [rowData]="eventsArr"
            [rowSelection]="'single'" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" (selectionChanged)="onSelectionChanged($event)"
            [columnDefs]="columnDefs">
      
        </ag-grid-angular>

        </div>
        
   
    </div>
  </main>
</div>