import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @Input() title: any = { title: "" };
  userFullName: string = "";

  @Input() hasButton: any = { value: false, buttonText: "" };
  @Output() buttonClicked = new EventEmitter();

  @Input() hasSecondButton: any = { value: false, secondButtonText: "" };
  @Output() secondButtonClicked = new EventEmitter();

  @Input() hasThirdButton: any = { value: false, thirdButtonText: "" };
  @Output() thirdButtonClicked = new EventEmitter();

  userMenuOpen: boolean = false;

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  clickButton() { // You can give any function name

    this.buttonClicked.emit(true);
  }

  
  clickSecondButton() { // You can give any function name

    this.secondButtonClicked.emit(true);
  }

  clickThirdButton() { // You can give any function name

    this.thirdButtonClicked.emit(true);
  }
  
  
  pages: any = [
    {
      name: "Entries",
      id: "entries"
    },
    {
      name: "Reports",
      id: "reports"
    },
    {
      name: "Items",
      id: "additional-items"
    },
    {
      name: "Notes",
      id: "notes"
    },
    {
      name: "Fees",
      id: "fees"
    }
  ];

  eventId: any;
  viewSideMenu: any = false;
  url: any = "";

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) {
    this.auth.user.subscribe(x => {
      this.userFullName = x.name;
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.url = this.router.url.split("/").pop();
    // console.log("url", this.url);
  }

  checkIfDashboard() {
    var result = true;

    for (let i = 0; i < this.pages.length; i++) {
      // console.log(this.url, this.pages[i]['id']);
      if (this.url === this.pages[i]['id']) {
        result = false;
      }
    }

    return result;
  }

  setClasses(active: Boolean) {
    let activeClasses = {
      'bg-emerald-900': true,
      'text-white': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-sm': true,
      'font-medium': true
    }

    let plainClasses = {
      'text-emerald-300': true,
      'hover:bg-emerald-800': true,
      'hover:text-white': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-sm': true,
      'font-medium': true
    }

    if (active) {
      return activeClasses;
    } else {
      return plainClasses;
    }

  }

  setClassesMobile(active: Boolean) {
    let activeClasses = {
      'bg-emerald-900': true,
      'text-white': true,
      'block': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-base': true,
      'font-medium': true,
      'w-full': true
    }

    let plainClasses = {
      'text-emerald-300': true,
      'hover:bg-emerald-700': true,
      'hover:text-white': true,
      'block': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-base': true,
      'font-medium': true,
      'w-full': true
    }

    if (active) {
      return activeClasses;
    } else {
      return plainClasses;
    }

  }

  logout() {
    // Todo run http request to remove cookie and router link
    this.auth.logout();
  }

  // settings() {
  //   this.router.navigate(['/'])
  // }

}
