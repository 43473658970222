import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'admin-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.scss']
})
export class EntriesComponent implements OnInit {

  data: any = [{
    eid: 'e101',
    ename: 'ravi',
    esal: 1000
  }, {
    eid: 'e102',
    ename: 'ram',
    esal: 2000
  }, {
    eid: 'e103',
    ename: 'rajesh',
    esal: 3000
  }];

  eventId: any;
  eventData: any = {};
  successfulEntries: any = [];
  failedEntries: any = [];
  paymentCollectedEntries: any = [];
  entries: any = [];
  searchField: any = "";

  view: any = 1;

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }


  switchView(int: any) {
    this.view = int;
    this.searchField = "";
  }

  constructor(private excelService: ExcelService, private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }


  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.entry.all(this.eventId).subscribe(
          response => {
            this.entries = response;
            console.log(this.entries);
          },
          err => console.log(err)
        );

        this.entry.allSuccessful(this.eventId).subscribe(
          response => {
            this.successfulEntries = response;
          },
          err => console.log(err)
        );

        this.entry.allFailed(this.eventId).subscribe(
          response => {
            this.failedEntries = response;
          },
          err => console.log(err)
        );

        this.entry.allPaymentCollected(this.eventId).subscribe(
          response => {
            this.paymentCollectedEntries = response;
          },
          err => console.log(err)
        );


      },
      err => console.log(err)
    );

  }


  exportToCSV() {

    var str = '';
    var header = "Id,Name,Email,Phone,State,Date";

    //Add header to output str
    str += header;
    str += '\n';

    for (var i = 0; i < this.entries.length; i++) {
      var line = '';
      if (line != '') line += ','


      if (this.entries[i]['_id']) {
        line += this.entries[i]['_id'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ','
      if (this.entries[i]['name']) {
        line += this.entries[i]['name'].replace(/,/g, '');
      } else {
        line += "";
      }


      line += ','
      if (this.entries[i]?.email) {
        line += this.entries[i]['email'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ','
      if (this.entries[i]?.phone) {
        line += this.entries[i]['phone'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ','
      if (this.entries[i]['state']) {
        line += this.entries[i]['state'];
      } else {
        line += "";
      }

      line += ','
      if (this.entries[i]?.createdAt) {
        line += this.entries[i]['createdAt'].replace(/,/g, '');
      } else {
        line += "";
      }


      str += line + '\n';
    }



    // const csvArray = header.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'allentries.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
