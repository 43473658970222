import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditDebitComponent } from './components/credit-debit/credit-debit.component';
import { CashComponent } from './components/cash/cash.component';
import { IncomeService } from './services/income.service';
import { TotalComponent } from './components/total/total.component';



@NgModule({
  declarations: [
    CreditDebitComponent,
    CashComponent,
    TotalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CreditDebitComponent,
    CashComponent,
    TotalComponent
  ],
  providers: [
    IncomeService
  ]
})
export class IncomeChargesModule { }
