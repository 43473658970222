<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <img class="mx-auto h-24 w-auto" src="../../../assets/images/logo_block_green.png" alt="myentryform">
    <div class="text-center">
      <!-- <span class="text-center antialiased text-gray-800 font-extrabold text-3xl">BarrelBook</span> -->
      <h2 class="mt-6 text-lg font-bold text-gray-900">
        Create Producer Account
      </h2>
    </div>
    <form class="mt-8 space-y-6" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <input type="hidden" name="remember" value="true">
      <div class="-space-y-px">
        <div>
          <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Full Name</label>
          <input id="name" formControlName="name" name="name" type="text" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
            placeholder="John Doe">

          <div
            *ngIf="!registerForm.get('name')?.valid && (registerForm.get('name')?.dirty ||registerForm.get('name')?.touched)">
            <p [hidden]="!registerForm.get('name').errors?.required" class="mt-2 text-sm text-red-600" id="name-error">
              Name is required</p>
            <p [hidden]="!registerForm.get('name').errors?.minlength" class="mt-2 text-sm text-red-600" id="name-error">
              Name must be longer than 3 letters</p>
            <p [hidden]="!registerForm.get('name').errors?.pattern" class="mt-2 text-sm text-red-600" id="name-error">
              Name must only contain letters and spaces</p>
          </div>
        </div>
        <div>
          <label for="email-address" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
          <input id="email-address" formControlName="email" name="email" type="email" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
            placeholder="john@example.com">
          <div
            *ngIf="!registerForm.get('email')?.valid && (registerForm.get('email')?.dirty ||registerForm.get('email')?.touched)">
            <p [hidden]="!registerForm.get('email').errors?.required" class="mt-2 text-sm text-red-600"
              id="email-error">Email is required</p>
            <p [hidden]="!registerForm.get('email').errors?.pattern" class="mt-2 text-sm text-red-600" id="email-error">
              Email must be valid</p>
          </div>
        </div>
        <div>
          <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Phone #</label>
          <input id="phone" formControlName="phone" name="phone" type="text" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
            placeholder="000-000-0000">
          <div
            *ngIf="!registerForm.get('phone')?.valid && (registerForm.get('phone')?.dirty ||registerForm.get('phone')?.touched)">
            <p [hidden]="!registerForm.get('phone').errors?.required" class="mt-2 text-sm text-red-600"
              id="phone-error">Phone number is required</p>
            <p [hidden]="!registerForm.get('phone').errors?.pattern" class="mt-2 text-sm text-red-600" id="phone-error">
              Must be a valid phone number</p>
          </div>
        </div>
        <div>
          <label for="address" class="block text-sm font-medium leading-6 text-gray-900">Address</label>
          <input id="address" formControlName="address" name="address" type="text" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
            placeholder="123, Main St, Chillicothe, OH, 45601">
          <div
            *ngIf="!registerForm.get('address')?.valid && (registerForm.get('address')?.dirty ||registerForm.get('address')?.touched)">
            <p [hidden]="!registerForm.get('address').errors?.required" class="mt-2 text-sm text-red-600"
              id="address-error">Address is required</p>
          </div>
        </div>


        <div class="sm:col-span-2">
          <label for="city" class="block text-sm font-medium text-gray-900">
            City
          </label>
          <div class="mt-1">
            <input type="text" name="city" id="city" formControlName="city"
              class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div
            *ngIf="!registerForm.get('city')?.valid && (registerForm.get('city')?.dirty ||registerForm.get('city')?.touched)">
            <p [hidden]="!registerForm.get('city').errors?.required" class="mt-2 text-sm text-red-600"
              id="address-error">City is required</p>
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="state" class="block text-sm font-medium text-gray-900">
            State
          </label>
          <div class="mt-1">
            <select name="state" id="state" formControlName="state"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
              <option *ngFor="let state of states; index as i;" value="{{state}}">{{state}}
              </option>
            </select>
          </div>
          <div
            *ngIf="!registerForm.get('state')?.valid && (registerForm.get('state')?.dirty ||registerForm.get('state')?.touched)">
            <p [hidden]="!registerForm.get('state').errors?.required" class="mt-2 text-sm text-red-600"
              id="state-error">State is required</p>
            <p [hidden]="!registerForm.get('state').errors?.minlength" class="mt-2 text-sm text-red-600"
              id="state-error">>State is required</p>
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="zip" class="block text-sm font-medium text-gray-900">
            ZIP
          </label>
          <div class="mt-1">
            <input type="text" name="zip" id="zip" formControlName="zip"
              class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div
            *ngIf="!registerForm.get('zip')?.valid && (registerForm.get('zip')?.dirty ||registerForm.get('zip')?.touched)">
            <p [hidden]="!registerForm.get('zip').errors?.required" class="mt-2 text-sm text-red-600" id="zip-error">
              Zip
              is required</p>
            <p [hidden]="!registerForm.get('zip').errors?.pattern" class="mt-2 text-sm text-red-600" id="zip-error">
              Must
              be valid zip code</p>
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
          <input id="password" formControlName="password" name="password" type="password" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6">
        </div>
        <div>
          <label for="confirmPassword" class="block text-sm font-medium leading-6 text-gray-900">Confirm
            Password</label>
          <input id="confirmPassword" formControlName="confirmPassword" name="confirmPassword" type="password" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6">
        </div>
      </div>
      <div>
        <button [disabled]="registerForm.invalid"  [ngClass]="registerForm.invalid == false ? 'bg-emerald-600 hover:bg-emerald-700' : 'bg-gray-400 hover:bg-emerald-500'" type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
          </span>
          Register
        </button>
      </div>
    </form>
  </div>
</div>