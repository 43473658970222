import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EventService } from 'src/app/core/services/event.service';
import { EntryService } from 'src/app/core/services/entry.service';
import { DrawService } from 'src/app/core/services/draw.service';
import { NotificationsService } from '@pascaliske/ngx-notifications';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  view: any = 0;
  eventId: any;
  classId: any;
  drawDate: any;

  eventData: any = {};
  classData: any = {};
  entries: any = [];
  completed: any = [];

  seperator: number = 30;

  modalViews: any = {
    "seperatorModal": false
  };

  switchView(index: any) {
  this.view = index;
  }


  toggleModal(modalName: any) {
    this.modalViews[modalName] = !this.modalViews[modalName];
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.entries, event.previousIndex, event.currentIndex);
  }

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  shuffle() {
    // this.entries = this.fisherYatesShuffle(this.entries);
    this.entries = this.crazyShuffle(this.fisherYatesShuffle(this.entries));
  }

  crazyShuffle(items: any) {

    //Randomize items fisher yates
    //Set first item as index 0 in results

    //Loop though items
    //If item is at least seperator length away from matching record
    //Push to result
    //If not add to later
    //each push run through each later to see if it can be inputed
    //At the end concat remaining later to end of list.

    let seperator = this.seperator;
    let later = [];
    let result: any = [];
    result.push(items[0]);

    for (let i = 1; i < items.length; i++) {
      //Set the result as blank until filled
      let current = items[i];
      let found = false;
      let foundIndex = 0;


      let rider = current['entry']?.name;
      let horse = current['name'];


      for (let previousX = 0; previousX < result.length; previousX++) {

        let previous = result[previousX];
        let previousRider = previous['entry']?.name;
        let previousHorse = previous['name'];

        if (previousRider === rider || previousHorse === horse) {
          found = true;
          foundIndex = previousX;
        }
      }

      if (found) {
        if (result.length > foundIndex + seperator) {
          result.push(current);
        } else {
          later.push(current);
        }
      } else {
        result.push(current);
      }


      //Check if any of the later or eligble and drop them in
      //IF not leave them and try again next time. 
      //For each later we will loop result to see if 
      //the !LAST! previous is seperator spots away 
      for (let laterX = 0; laterX < later.length; laterX++) {
        let found = false;
        let foundIndex = 0;

        let laterRider = later[laterX]['entry']?.name;
        let laterHorse = later[laterX]['name'];

        for (let previousX = 0; previousX < result.length; previousX++) {

          let laterPrevious = result[previousX];
          let laterPreviousRider = laterPrevious['entry']?.name;
          let laterPreviousHorse = laterPrevious['name'];


          if (laterPreviousRider === laterRider || laterPreviousHorse === laterHorse) {
            found = true;
            foundIndex = previousX;
          }
        }

        if (found) {
          if (result.length > foundIndex + seperator) {
            result.push(later[laterX]);
            later.splice(laterX, 1);
          }
        }


      }

    }
    result.push(...later);

    console.log(later);
    console.log(result);
    return result;


  }

  fisherYatesShuffle(items: any) {
    var counter = items.length;
    while (counter > 0) {
      var x = Math.floor(Math.random() * counter);
      counter--;
      var temp = items[counter];
      items[counter] = items[x];
      items[x] = temp;
    }
    return items;
  }

  constructor(private draw: DrawService, private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService, private notificationService: NotificationsService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.classId = routeParams.get('classId');
    this.drawDate = routeParams.get('date');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.event.getOneClasses(this.classId).subscribe(
          response => {

            this.classData = response;
            console.log(this.classData);

            this.entry.classByDateNoDraw(this.classId, this.drawDate).subscribe(
              response => {
                this.entries = response;
                console.log(response);
              },
              err => console.log(err)
            );

            this.entry.classByDateCompletedDraw(this.classId, this.drawDate).subscribe(
              response => {
                this.completed = response;
                console.log(response);
              },
              err => console.log(err)
            );

          },
          err => console.log(err)
        );
      },
      err => console.log(err)
    );


  }

  getDrawInOrder() {

    var result = [];
    for (let i = 0; i < this.entries.length; i++) {

      result.push(this.entries[i]['_id'])

    }

    return result;

  }

  resetOrder() {
    this.draw.resetRunOrder(this.classId, this.drawDate).subscribe(
      response => {

        console.log(response);
        this.notificationService.success('Draw Order Has Been Reset!');
        this.router.navigate(['showtime', this.eventId]);
      },
      err => console.log(err)
    );
  }

  saveOrder() {

    var data = {
      "date": this.drawDate, 
      "event": this.eventId,
      "class": this.classId,
      "runOrder": this.getDrawInOrder()
    }

    this.draw.updateRunOrder(this.classId, this.drawDate, data).subscribe(
      response => {

        console.log(response);
        this.notificationService.success('Draw Order Has Been Saved!');
        this.router.navigate(['showtime', this.eventId]);
      },
      err => console.log(err)
    );



  }

}
