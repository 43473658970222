import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@pascaliske/ngx-notifications';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  entryId: any = "";
  eventId: any = "";
  entryData: any = {};
  entries: any = [];
  sidepotEntries: any = [];
  additionalItems: any = [];
  classArr: any = [];

  groupedEntriesByClass: any = {};
  groupedSidePotsByClass: any = {};


  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  toggleModal(modalName: any) {
    this.modalViews[modalName] = !this.modalViews[modalName];
  }


  modalViews: any = {
    "deleteEntryModal": false,
    "refundEntryModal": false
  };


  constructor(private router: Router, private notificationService: NotificationsService, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.entryId = routeParams.get('entryId');
    this.eventId = routeParams.get('id');

    this.entry.getOne(this.entryId).subscribe(
      response => {

        this.entryData = response;
        console.log(this.entryData);


        this.event.getClasses(this.eventId).subscribe(
          response => {
            this.classArr = response;
          },
          err => console.log(err)
        );


        for (let i = 0; i < this.entryData['classes'].length; i++) {
          const currentClassEntry = this.entryData['classes'][i];
          if (!this.groupedEntriesByClass[currentClassEntry['class']['_id']]) {
            this.groupedEntriesByClass[currentClassEntry['class']['_id']] = this.entryData['classes'].filter((classEntry: any) => classEntry.class['_id'] == currentClassEntry['class']['_id']);
          }

          if (!this.groupedSidePotsByClass[currentClassEntry['class']['_id']]) {
            const data = this.entryData['sidePots'].filter((sidepot: any) => sidepot.class == currentClassEntry['class']['_id']);

            if (data.length) {
              this.groupedSidePotsByClass[currentClassEntry['class']['_id']] = data;
            }

          }

        }

      },
      err => console.log(err)
    );

  }


  delete(entryId: any) {
    this.entry.removeAll(entryId).subscribe(
      response => {

        console.log(response);
      },
      err => console.log(err)
    );
    this.router.navigate(['events', this.entryData['event'], 'entries']);

  }

  markPaid() {

    this.entry.markedPaid(this.entryData['_id'], { "value": this.entryData['markedPaid'] }).subscribe(
      response => {

        this.notificationService.success("Entry has been updated");

      },
      err => console.log(err)
    );
  }

  deleteSidePotEntry(sidePotId: any) {

    for (let i = 0; i < this.entryData['sidePots'].length; i++) {

      if (this.entryData['sidePots'][i]['_id'] === sidePotId) {
        this.entryData['sidePots'].splice(i, 1);
        break;
      }

    }


    this.entry.removeSidePotEntry(this.entryId, sidePotId, this.entryData['sidePots']).subscribe(
      response => {
        console.log(response);
      },
      err => console.log(err)
    );

  }

}
