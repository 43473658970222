import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class IncomeService {
  eventCreditIncomeData: any = {
    "success": true,
    "totalCollected": 0,
    "accountBalance": 0,
    "totalProcessingFees": 0,
    "stripeFees": 0,
    "barrelbookFee": 0,
    "totalEntries": 0
  };
  eventCashIncomeData: any = {
    "success": true,
    "totalCollected": 0,
    "accountBalance": 0,
    "totalProcessingFees": 0,
    "stripeFees": 0,
    "barrelbookFee": 0,
    "totalEntries": 0
  };

  eventTotalIncomeData: any = {
    "success": true,
    "totalCollected": 0,
    "accountBalance": 0,
    "totalProcessingFees": 0,
    "stripeFees": 0,
    "barrelbookFee": 0,
    "totalEntries": 0
  };

  constructor(private http: HttpClient) { }

  eventCreditIncome(id: any) {
    return this.http.get<{}>(environment.apiUrl + 'income/event/credit/' + id);
  }

  getEventCreditIncome(id: any) {
    this.eventCreditIncomeData = {
      "success": true,
      "totalCollected": 0,
      "accountBalance": 0,
      "totalProcessingFees": 0,
      "stripeFees": 0,
      "barrelbookFee": 0,
      "totalEntries": 0
    };
    this.eventCreditIncome(id).subscribe(
      response => {
        console.log('Credit Income Data', response);
        this.eventCreditIncomeData = response;
      },
      err => console.log(err)
    );
  }

  eventCashIncome(id: any) {
    return this.http.get<{}>(environment.apiUrl + 'income/event/cash/' + id);
  }

  getEventCashIncome(id: any) {
    this.eventCashIncomeData = {
      "success": true,
      "totalCollected": 0,
      "accountBalance": 0,
      "totalProcessingFees": 0,
      "stripeFees": 0,
      "barrelbookFee": 0,
      "totalEntries": 0
    };
    this.eventCashIncome(id).subscribe(
      response => {
        console.log('Cash Income Data', response);
        this.eventCashIncomeData = response;
      },
      err => console.log(err)
    );
  }

  eventTotalIncome(id: any) {
    return this.http.get<{}>(environment.apiUrl + 'income/event/all/' + id);
  }

  getEventTotalIncome(id: any) {
    this.eventCashIncomeData = {
      "success": true,
      "totalCollected": 0,
      "accountBalance": 0,
      "totalProcessingFees": 0,
      "stripeFees": 0,
      "barrelbookFee": 0,
      "totalEntries": 0
    };
    this.eventTotalIncome(id).subscribe(
      response => {
        console.log('Total Income Data', response);
        this.eventTotalIncomeData = response;
      },
      err => console.log(err)
    );
  }



}
