<div class="bg-gray-100">
  <app-main [title]="{title: classData['name'] + ' entries'}" [hasButton]="{value: true, buttonText: 'Export'}"
    (buttonClicked)="exportToCSV()"></app-main>



  <main class="-mt-32">

    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">

      <div class="shadow rounded-lg">

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Member #
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rider
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                District
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Horse
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rollover
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Entry Date
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Run Date
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Notes
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">

            <tr *ngFor="let item of entries;" class="bg-white">



              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['entry']?.memberNumber}}
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['entry']?.name}}
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['entry']?.district}}
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['name']}}
              </td>

              <td *ngIf="item['rollover']" class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['rollover']['rollTo']['name']}}
              </td>

              <td *ngIf="!item['rollover']" class="px-6 py-4 text-sm font-medium text-gray-900">
                No
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <time datetime="2020-09-20">{{item['entry']?.createdAt | date:'shortDate'}}</time>
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <time datetime="2020-09-20">{{item['date'] | date:'shortDate'}}</time>
              </td>

              <td class="px-6 py-4 text-sm font-medium text-gray-900">
                {{item['notes']}}
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  </main>

</div>