import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        

        // add authorization header with jwt token if available
        let currentUser = this.auth.userValue;
        // if (currentUser) {
          request = request.clone({ withCredentials: true });
        // }
     
        // if (currentUser && currentUser.token) {

        //   request = request.clone({
        //     setHeaders: {
        //       'token': currentUser.token
        //     }
        //   });
        // }

        return next.handle(request);
    }
}