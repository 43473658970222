import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  public loginInvalid: boolean = false;

  constructor(private auth: AuthService, private _formBuilder: FormBuilder, private router: Router) { 
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }


  onSubmit(): void {

    // console.warn('Event Has Been Created', this.createEventForm.value);
    // this.event.add(this.createEventForm.value);
     //this.createEventForm.reset();
     this.auth.login( this.loginForm.value['email'],  this.loginForm.value['password'])
      .pipe(first())
      .subscribe(
        data => {
          if (data['loggedIn']) {
            
            if (data['user']['passwordResetPending']) {
              this.router.navigate(['new-password']);
            } else {
              if (data['user']['superuser'] === true) { 
                this.router.navigate(['superuser', 'organizations']);
              } else {
                this.router.navigate(['events']);
              }
            }
           
          } else {
            this.loginInvalid = true;
          }
        
        },
        error => {
          console.log(JSON.stringify(error))
          this.loginForm.reset();
        });


  }

}
