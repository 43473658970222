import { HttpClient, HttpEventType } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { EventClass } from '../interfaces/EventClass';
import { Event } from '../interfaces/event';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EventService {

  constructor(private http: HttpClient) { }

  public events: Event[] = [];

  add(data: any) {

    return this.http.post<Event[]>(environment.apiUrl + 'event/', data);

  }

  remove(id: any) {

    return this.http.delete<Event[]>(environment.apiUrl + 'event/' + id);

  }


  getAll() {
    return this.http.get<Event[]>(environment.apiUrl + 'event/');
  }

  getAllByCompany(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'event/company/' + id);
  }


  getOne(id: any) {
    return this.http.get<Event>(environment.apiUrl + 'event/' + id);
  }

  edit(id: any, data: Event) {
    return this.http.put<Event>(environment.apiUrl + 'event/' + id, data);
  }

  //Classes within an event
  getClasses(id: any) {
    return this.http.get<EventClass>(environment.apiUrl + 'classes/event/' + id);
  }

  getOneClasses(id: any) {
    return this.http.get<EventClass>(environment.apiUrl + 'classes/' + id);
  }

  getOneSidePot(id: any) {
    return this.http.get<EventClass>(environment.apiUrl + 'sidepot/' + id);
  }

  addOneSidePot(classId: any, data:any) {
    return this.http.post<EventClass>(environment.apiUrl + 'classes/addSidepot/' + classId, data);
  }

  deleteOneSidepot(id: any) {
    return this.http.delete<EventClass>(environment.apiUrl + 'sidepot/remove/' + id);
  }

  addOneRollover(classId: any, data:any) {
    return this.http.post<EventClass>(environment.apiUrl + 'classes/addRollover/' + classId, data);
  }

  deleteOneRollover(id: any) {
    return this.http.delete<EventClass>(environment.apiUrl + 'rollover/remove/' + id);
  }


  createClass(data: any) {

    return this.http.post<EventClass[]>(environment.apiUrl + 'classes/', data);

  }

  editClass(_id: any, data: any) {

    return this.http.put<EventClass[]>(environment.apiUrl + 'classes/' + _id, data);

  }



  uploadAttachments(form: any) {
    return this.http.post(environment.apiUrl + 'event/attachments/upload', form, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event: any) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  uploadLogo(form: any, eventId: any) {
    return this.http.post(environment.apiUrl + 'logo/upload/' + eventId, form, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event: any) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


  viewAttachment(id: any) {
    return this.http.get(environment.apiUrl + 'event/attachments/view/' + id);
  }

  getAttachments(id: any) {
    return this.http.get(environment.apiUrl + 'event/attachments/list/' + id);
  }

  deleteAttachment(id: any) {
    return this.http.delete(environment.apiUrl + 'event/attachments/' + id);
  }

  createAdditionalItem(data: any) {

    return this.http.post<[]>(environment.apiUrl + 'additionalitem/', data);

  }

  duplicate(id: any) {

    return this.http.post<[]>(environment.apiUrl + 'event/duplicate/' + id, {});

  }

  getAdditionalItems(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'additionalitem/event/' + id);
  }

  getOneAdditionalItem(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'additionalitem/' + id);
  }

  editOneAdditionalItem(data: any) {
    return this.http.put<[]>(environment.apiUrl + 'additionalitem/' + data['_id'], data);
  }

  deleteOneAdditionalItem(id: any) {
    return this.http.delete(environment.apiUrl + 'additionalitem/' + id);
  }


  fees(id: any) {
    return this.http.get(environment.apiUrl + 'event/fees/' + id);
  }

  createFee(data: any) {

    return this.http.post<[]>(environment.apiUrl + 'fee/', data);

  }

  deleteFee(_id: any) {
    return this.http.delete(environment.apiUrl + 'fee/' + _id);
  }

  getRunOrder(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'classes/runOrder/' + id);
  }

  updateRunDurations(data: any) {

    return this.http.put<[]>(environment.apiUrl + 'classes/runOrder/updateRunDurations', data);

  }

  deleteClass(id: any) {
    return this.http.delete(environment.apiUrl + 'classes/' + id);
  }




}
