<div class="bg-gray-100">
    <app-main [title]="{title: 'Reports'}"></app-main>
  
    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow">
          

            <div class="sm:block">
              <div class="border-b border-gray-200">
                <div class="px-5 sm:px-6 py-4">
                  <div class="grid grid-cols-1 gap-8">
                    <div>
                      <div class="min-w-0 flex-1 px-12">
                        <div class="mx-auto w-full max-w-xs">
                          <label for="desktop-search" class="sr-only">Search</label>
                          <div class="relative text-gray-400 focus-within:text-gray-600">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <!-- Heroicon name: mini/magnifying-glass -->
                              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <input
                              class="block w-full rounded-md border border-gray-200 bg-white py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600 sm:text-sm"
                              placeholder="Search" type="search" name="search" [(ngModel)]="searchField">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
 
  
  
            <div class="mx-auto grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 p-6">
              
              <article *ngFor="let report of reports | filter : searchField" class="flex max-w-xl flex-col items-start justify-between bg-gray-50 rounded-lg shadow-xl p-4">
                <div class="flex items-center gap-x-4 text-xs">
                  <span class="relative z-10 rounded-full bg-emerald-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-emerald-200">{{report.tag}}</span>
                </div>
                <div class="group relative">
                  <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <p>
                      <span class="absolute inset-0"></span>
                      {{report.name}}
                    </p>
                  </h3>
                  <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{report.description}}</p>
                </div>
                <button (click)="openReport(report.reportName)" type="button" class="rounded bg-emerald-50 px-2 py-1 text-xs font-semibold text-emerald-600 shadow-sm hover:bg-emerald-100 mt-4">Download</button>
              </article>

        
        

            </div>
        
  
  
  
        </div>
      </div>
  
    </main>
  
  </div>