<div class="bg-gray-100">
  <app-main [title]="{title: 'Additional Items'}"></app-main>



  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Available
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let item of additionalItems;">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['name']}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{item['quantity'] - item['amountSold']}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a (click)="editItem(item['_id'])" class="text-gray-500 hover:text-gray-900">Edit</a>
                <a [routerLink]="['/events/items', eventId, item['_id']]"
                  class="ml-2 text-emerald-600 hover:text-emerald-900">Open</a>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </main>



  <div *ngIf="modalViews['editItemModal']" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>

          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Edit
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Make changes to this item below.
              </p>
            </div>

            <div class="relative flex items-start mt-2 py-4 ">
              <div class="min-w-0 flex-1 text-sm">
                <label for="soldOut" class="font-medium text-gray-700 select-none">Sold Out</label>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input [(ngModel)]="editData['soldOut']" id="soldOut" name="soldOut" type="checkbox"
                  class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
              </div>
            </div>

            <div class="relative flex items-start mt-2 py-4 ">
              <div class="min-w-0 flex-1 text-sm">
                <label for="perDay" class="font-medium text-gray-700 select-none">Per Day</label>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input [(ngModel)]="editData['perDay']" id="perDay" name="perDay" type="checkbox"
                  class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
              </div>
            </div>

            <div class="mt-2">
              <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input [(ngModel)]="editData['name']" type="text" name="name" id="name"
                  class="focus:ring-emerald-500 focus:border-emerald-500 block w-full  sm:text-sm border-gray-300 rounded-md"
                  placeholder="Stalls" aria-describedby="Item name">
              </div>
            </div>

            <div class="mt-2">
              <label for="notes" class="block text-sm font-medium text-gray-700">Notes</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input [(ngModel)]="editData['notes']" type="text" name="notes" id="notes"
                  class="focus:ring-emerald-500 focus:border-emerald-500 block w-full  sm:text-sm border-gray-300 rounded-md"
                  placeholder="If buying stalls for more than one contestant please list names in notes below" aria-describedby="Item notes">
              </div>
            </div>

            <div class="mt-2">
              <label for="price" class="block text-sm font-medium text-gray-700">Price</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm">
                    $
                  </span>
                </div>
                <input [(ngModel)]="editData['price']" type="number" name="price" id="price"
                  class="focus:ring-emerald-500 focus:border-emerald-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00" aria-describedby="price-currency">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <label for="quantity" class="block text-sm font-medium text-gray-700">Quantity</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input [(ngModel)]="editData['quantity']" type="number" name="quantity" id="quantity"
                  class="focus:ring-emerald-500 focus:border-emerald-500 block w-full  sm:text-sm border-gray-300 rounded-md"
                  placeholder="1" aria-describedby="Item quantity">
              </div>
            </div>


            <div class="mt-2">
              <label for="purchaseQuantityLimit" class="block text-sm font-medium text-gray-700">Purchase Quantity Limit</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input [(ngModel)]="editData['purchaseQuantityLimit']" type="number" name="purchaseQuantityLimit" id="purchaseQuantityLimit"
                  class="focus:ring-emerald-500 focus:border-emerald-500 block w-full  sm:text-sm border-gray-300 rounded-md"
                  placeholder="1" aria-describedby="Item purchaseQuantityLimit">
              </div>
            </div>


          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button (click)="submitEdit()" type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-3 sm:w-auto sm:text-sm">
            Submit
          </button>
          <button type="button" (click)="toggleModal('editItemModal')"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm">
            Cancel
          </button>
          <button (click)="deleteItem()" type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mr-3 sm:mt-0 sm:w-auto sm:text-sm">
            Delete
        </button>
        </div>
      </div>
    </div>
  </div>
