import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  resetForm: FormGroup;
  email: string = "";

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('confirmNewPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  constructor(private auth: AuthService, private _formBuilder: FormBuilder, private router: Router) {
    this.resetForm = this._formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    }, { validators: this.checkPasswords });

    this.auth.user.subscribe(x => {
      this.email = x.email;
    });

  }

  ngOnInit(): void {
  }

  onSubmit(): void {

     this.auth.changePassword(this.email, this.resetForm.value['password'], this.resetForm.value['newPassword'])
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/login']);
        },
        error => {
          this.resetForm.reset();
        });


  }

}
