import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'BarrelBook';
  userMenu = false;

  currentUser: any;

  constructor(private auth: AuthService, public router: Router) {
  
  }

  toggleUserMenu() {
    this.userMenu = !this.userMenu;
  }

  onActivate(event: any) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}

  logout() {
    this.auth.logout();
  }

  ngOnInit(): void {
    this.auth.user.subscribe(x => (this.currentUser = x));
  }



}
