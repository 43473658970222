import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';


@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  eventId: any;
  itemId: any;
  eventData: any = {};
  additionalItemData: any = {};
  purchases: any = [];
  selectedItemId: any = "";

  //Modals
  modalViews: any = {
    "deleteItemModal": false
  };

  toggleModal(modalName: any) {
    this.modalViews[modalName] = !this.modalViews[modalName];
  }

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }
  
  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }


  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.itemId = routeParams.get('itemid');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.event.getOneAdditionalItem(this.itemId).subscribe(
          response => {

            this.additionalItemData = response;

            this.entry.items(this.itemId).subscribe(
              response => {
                this.purchases = response;
              },
              err => console.log(err)
            );

          },
          err => console.log(err)
        );




      },
      err => console.log(err)
    );


  }

  deleteItemEntryComplete(id: any) {
    this.entry.itemsDeleteOne(id).subscribe(
      response => {
        this.entry.items(this.itemId).subscribe(
          response => {
            this.purchases = response;
            this.modalViews['deleteItemModal'] = false;
          },
          err => console.log(err)
        );
      },
      err => console.log(err)
    );

  
  }

  deleteItemEntry(id: any) {
    this.selectedItemId = id;
    console.log("Selected Item Id", this.selectedItemId)
    this.modalViews['deleteItemModal'] = true;
    
  }

  exportToCSV() {

    var str = '';
    var header = "Item,Name,Email,Phone,Amount,Notes,Date";

    //Add header to output str
    str += header;
    str += '\n';

    for (var i = 0; i < this.purchases.length; i++) {
      var line = '';
      if (line != '') line += ','


      if (this.purchases[i]['item']['name']) {
        line += this.purchases[i]['item']['name'].replace(/,/g, '');
      } else {
        line += "";
      }
      
      line += ',' 
      if (this.purchases[i]['entry']?.name) {
        line += this.purchases[i]['entry']['name'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ',' 
      if (this.purchases[i]['entry']?.email) {
        line += this.purchases[i]['entry']['email'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ',' 
      if (this.purchases[i]['entry']?.phone) {
        line += this.purchases[i]['entry']['phone'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ','
      if (this.purchases[i]['amount']) {
        line += this.purchases[i]['amount'];
      } else {
        line += "";
      }

      line += ','
      if (this.purchases[i]['entry']['notes']) {
        line += this.purchases[i]['entry']['notes'].replace(/,/g, '').replace(/\r?\n|\r/, '');
      } else {
        line += "";
      }


      line += ','
      if (this.purchases[i]['entry']?.createdAt) {
        line += this.purchases[i]['entry']['createdAt'].replace(/,/g, '');
      } else {
        line += "";
      }

  
      str += line + '\n';
    }



    // const csvArray = header.join('\r\n');

    const a = document.createElement('a');
    var universalBOM = "\uFEFF";
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+str);
    a.download = this.additionalItemData['name'] + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


}
