import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-superuser-events',
  templateUrl: './superuser-events.component.html',
  styleUrls: ['./superuser-events.component.scss']
})
export class SuperuserEventsComponent implements OnInit {

  eventsArr: any = [];

  constructor(private events: EventService) { }

  ngOnInit(): void {

    this.events.getAll().subscribe(
      response => {
        this.eventsArr = response;
      },
      err => console.log(err)
    );
  }

  private gridApi!: GridApi<any>;

  columnDefs: ColDef[] = [
    { field: 'approved', headerName: 'Approved', minWidth: 200 },
    { colId: 'name', field: 'name', resizable: true, minWidth: 300, cellClass: 'bold' },
    {
      field: 'openingDateTime', headerName: 'Entries open', minWidth: 200, cellRenderer: (data: any) => {
        return moment(data.value).format('MMMM Do YYYY, h:mm:ss a')
      }
    },
    {
      field: 'closingDateTime', headerName: 'Entries close', minWidth: 200, cellRenderer: (data: any) => {
        return moment(data.value).format('MMMM Do YYYY, h:mm:ss a')
      }
    },
    { field: 'complete', headerName: 'Complete', minWidth: 200 },
    { field: 'rodeo', headerName: 'Rodeo', minWidth: 200 }
  ];

  gridOptions: any = {
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: 'name',
          minWidth: 300
        }
      ]
    },
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }


  onSelectionChanged($event: any) {
    const selectedRows = this.gridApi.getSelectedRows();
    // this.auth.changeCurrentCompany(selectedRows[0]['_id'])
    // this.router.navigate(['events']);

  }


  onFilterTextBoxChanged($event: any) {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

}
