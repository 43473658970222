import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  public loginInvalid: boolean = false;
  states: any = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

  constructor(private auth: AuthService, private _formBuilder: FormBuilder, private router: Router) { 
    this.registerForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]],
      phone: ['', [Validators.required, Validators.pattern("[- +()0-9]+")]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.minLength(3), Validators.required]],
      zip: ['', [Validators.required, Validators.pattern("[0-9]{5}")]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }


  onSubmit(): void {

    // console.warn('Event Has Been Created', this.createEventForm.value);
    // this.event.add(this.createEventForm.value);
     //this.createEventForm.reset();
     this.auth.register( 
      this.registerForm.value['name'], 
      this.registerForm.value['phone'], 
      this.registerForm.value['address'], 
      this.registerForm.value['city'], 
      this.registerForm.value['state'], 
      this.registerForm.value['zip'], 
      this.registerForm.value['email'],  
      this.registerForm.value['password']
      )
      .pipe(first())
      .subscribe(
        data => {
          console.log('Loggin Data', data);
          if (data['loggedIn']) {
        
              this.router.navigate(['events']);
            
          } else {
            this.loginInvalid = true;
          }
        
        },
        error => {
          console.log(JSON.stringify(error))
          this.registerForm.reset();
        });


  }

}
