import { Component, Input, OnInit } from '@angular/core';
import { IncomeService } from '../../services/income.service';

@Component({
  selector: 'total-balance',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss']
})
export class TotalComponent implements OnInit {

  @Input() eventId: string = "";

  constructor(public income: IncomeService) { }

  ngOnInit(): void {
    this.income.getEventTotalIncome(this.eventId);
  }

}
