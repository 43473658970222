import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class showtimeSocketService {
    constructor(private socket: Socket) {
      
    }

    timerInput(data: any) {
        this.socket.emit('timerInput', data);
    }

    OnConnect() {
		return this.socket.fromEvent('connected');
	}
}