<div class="bg-gray-100">
    <app-main [title]="{title: 'Contestant Notes'}"></app-main>
  
    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
         
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Notes
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
  
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
  
              <tr *ngFor="let item of entries;" class="bg-white" [routerLink]="['/events', this.eventId, 'entries', item['_id']]">
    
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{item.name}}
                </td>
                
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{item.phone}}
                </td>
                
                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                  {{item.notes}}
                </td>
  
              </tr>
  
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
  
  