import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public activeUser: BehaviorSubject<any>;
  public user: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    // @ts-ignore
    this.activeUser = new BehaviorSubject<{}>(JSON.parse(localStorage.getItem('user')));
    this.user = this.activeUser.asObservable();
  }

  public get userValue(): User {
    return this.activeUser.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(environment.apiUrl + 'user/login', { email, password })
      .pipe(map(user => {
        // login success
        if (user) {

          var obj = {
            "_id": "",
            "name": "",
            "email": "",
            "company": "",
            "token": "",
            "passwordResetPending": false
          };

          obj['_id'] = user['user']._id;
          obj['name'] = user['user'].name;
          obj['email'] = user['user'].email;
          obj['company'] = user['user'].company;
          obj['passwordResetPending'] = user['user'].passwordResetPending;

          // store user, token local to keep user logged in refreshes
          localStorage.setItem('user', JSON.stringify(obj));
          this.activeUser.next(obj);
        }

        return user;
      }));
  }

  changePassword(email: string, currentPassword: string, newPassword: string) {
    return this.http.post<any>(environment.apiUrl + 'user/changePassword', { email, currentPassword, newPassword }).pipe(map(response => {
      return response
    }));

  }

  resetPassword(email: string) {
    return this.http.post<any>(environment.apiUrl + 'user/resetPassword', { email });
  }

  register(name: string, phone: string, address: string, city: string, state: string, zip: string, email: string, password: string) {
    return this.http.post<any>(environment.apiUrl + 'user/create', { 
      name,
      phone,
      address,
      city,
      state,
      zip,
      email,
      password
    })
      .pipe(map(newUser => {
        // register success
        if (newUser) {

          var obj = {
            "_id": "",
            "name": "",
            "email": "",
            "company": "",
            "token": ""
          };

          obj['_id'] = newUser['user']._id;
          obj['name'] = newUser['user'].name;
          obj['email'] = newUser['user'].email;
          obj['company'] = newUser['user'].company;

          // store user, token local to keep user logged in refreshes
          localStorage.setItem('user', JSON.stringify(obj));
          this.activeUser.next(obj);
        }

        return newUser;
      }));
  }


  changeCurrentCompany(orgId: string) {
    const modifiedUser = {
      ...this.activeUser.value,
      company: orgId
    }
    this.activeUser.next(modifiedUser);
  }

  create(name: string, email: string, password: string, company: string) {
    let type = 1;
    return this.http.post<any>(environment.apiUrl + 'user/', { name, email, password, company })
      .pipe(map(response => {
        return response
      }));
  }

  logout() {
    return this.http.get<any>(environment.apiUrl + 'user/logout').subscribe(data => {
      this.activeUser.next(null);
      localStorage.removeItem('user');
      this.router.navigate(['/login']);  
    })
  }






}
