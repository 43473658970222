import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/core/services/company.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-create-org',
  templateUrl: './create-org.component.html',
  styleUrls: ['./create-org.component.scss']
})
export class CreateOrgComponent implements OnInit {

  isLinear = false;
  createOrgForm: FormGroup;
  eventId: any;

  config: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY'
  }

  dateTimeconfig: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY HH:mm:ss'
  }


  constructor(private _formBuilder: FormBuilder, private company: CompanyService, private route: ActivatedRoute, private router: Router) {
    
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
   
    this.createOrgForm = this._formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      start:  new FormControl('', Validators.required),
      end:  new FormControl('', Validators.required), 
      openingDateTime: new FormControl('', Validators.required),
      closingDateTime: new FormControl('', Validators.required) 
    });
  
  }

  ngOnInit() {
  
  }

  onSubmit(): void {

    console.log(this.createOrgForm.value);
    this.company.add(this.createOrgForm.value).subscribe(
      response => {
        console.warn('Response', response);
        console.warn('Organization Has Been Created', this.createOrgForm.value);
        // this.event.add(this.createOrgForm.value);
        // this.createOrgForm.reset();

        // this.snackbar.open('Event Has Been Created', 'Dismiss', {
        //   duration: 3000
        // });
        
        this.router.navigate(['superuser']);
      },
      err => console.log(err)
    );


  }

}
