<div class="bg-emerald-700 pb-32">
  <nav class="bg-emerald-700">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="border-b border-emerald-700">
        <div class="flex items-center justify-between h-16 px-4 sm:px-0">
          <div class="flex items-center">
            <div class="flex-shrink-0">

              <svg [routerLink]="['/events']" class="block h-12 w-12" version="1.1" id="Layer_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <style type="text/css">
                  .st0 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: #ffffff;
                  }

                  .st1 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 30;
                    stroke-miterlimit: 10;
                  }
                </style>
                <path class="st0" d="M142.72,470.61C65.43,429.77,12.59,348.56,12.59,255.31c0-134.18,109.39-243.46,243.34-243.46
	c134.06,0,243.47,109.27,243.47,243.46c0,89.63-48.9,168.2-121.1,210.41c-9.79-12.58-15.49-30.33-18.79-51.29
	c-6.83-43.43-4.25-88.94-4.62-132.73c-0.52-57.84-1.95-104.07-48.12-145.61c-14.68-13.19-31.9-22.28-49.76-22.28H109.97
	c15.73,8.94,17.56,21.59,48.5,26.8v56.45c0,15.84,9.77,31.69,9.77,49.98v50.28c0,5.66,7.09,8.05,16.36,7.34l16.37-1.28
	c6.08-0.47,10.93-1.52,12.24-7.24c1.58-6.96,2.11-13.81,4.12-19.97c5.29-16.13,14.42-25.57,22.78-39.63
	c9.96-16.66,15.13-35.65,4.68-61.16c9.43,11.2,13.94,23.05,14.54,35.23c1.36,28.76-17.79,53.3-34.47,74.71
	c-28.46,36.65-69.92,72.33-80.42,119.02c-4.59,20.51-4.68,42.68-2.24,66.15L142.72,470.61L142.72,470.61z M170.36,321.43
	c5.42-2.32-15.24,12.5-34.45,37.96c-12.28,16.3-24.03,37.1-27.75,60.72c-45.19-40.63-73.77-99.52-73.77-164.8
	c0-51.56,17.73-98.99,47.42-136.73c32.31,4.48,33.58,30.4,60.8,37.73v34.45c0,26.79,8.94,43.28,8.94,56.03v51.25
	C151.01,316.56,166.01,323.34,170.36,321.43L170.36,321.43L170.36,321.43z M365.98,158.45c-32.68-48.34-77.7-60.49-112.08-60.49
	H100.19c40.08-39.75,95.16-64.33,155.75-64.33c122.06,0,221.67,99.53,221.67,221.67c0,43.81-12.85,84.85-35.08,119.3
	c-28.38-39.94-33.49-84.44-43.64-131C392.45,214.06,384.05,185.2,365.98,158.45L365.98,158.45z" />
                <circle class="st1" cx="256" cy="256.18" r="238" />
              </svg>


            </div>
            <div class="hidden md:block">
              <div class="ml-6 flex items-baseline space-x-4">
                <!-- Current: "bg-emerald-900 text-white", Default: "text-emerald-300 hover:bg-emerald-700 hover:text-white" -->
                <button [routerLink]="['/events', eventId]"
                  [ngClass]="setClasses(checkIfDashboard())">Dashboard</button>

                <button *ngFor="let page of pages;" [routerLink]="['/events', eventId, page.id]"
                  [ngClass]="setClasses(page.id === url)">{{page.name}}</button>

                <!-- <a href="#" class="text-emerald-300 hover:bg-emerald-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Team</a> -->

                <a (click)="logout()" class="block px-4 py-2 text-sm text-emerald-700" role="menuitem" tabindex="-1"
                id="user-menu-item-2">Sign out</a>

              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">

              <!-- <button type="button" [routerLink]="['/events', eventId, 'settings']"
                class="flex-shrink-0 p-1 text-emerald-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Settings</span>

                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </button> -->
              <!-- Profile dropdown -->
              <div class="ml-3 relative">
                <div class="flex inline-flex" (click)="toggleUserMenu()">
                  <span class="text-white">{{userFullName}}</span>
                  <button type="button"
                    class="ml-3 max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-emerald-800 text-white focus:ring-white"
                    id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                    <span class="sr-only">Open user menu</span>
                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>                    
                  </button>
                </div>


                <div *ngIf="userMenuOpen"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

                  <a [routerLink]="['/events', eventId, 'settings']" class="block px-4 py-2 text-sm text-emerald-700" role="menuitem" tabindex="-1"
                    id="user-menu-item-1">Settings</a>

                  <a (click)="logout()" class="block px-4 py-2 text-sm text-emerald-700" role="menuitem" tabindex="-1"
                    id="user-menu-item-2">Sign out</a>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button type="button" (click)="toggleSideMenu()"
              class="bg-emerald-800 inline-flex items-center justify-center p-2 rounded-md text-emerald-400 hover:text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-emerald-800 focus:ring-white"
              aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!--
                  Heroicon name: outline/menu

                  Menu open: "hidden", Menu closed: "block"
                -->
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!--
                  Heroicon name: outline/x

                  Menu open: "block", Menu closed: "hidden"
                -->
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div *ngIf="viewSideMenu" class="border-b border-emerald-700 md:hidden" id="mobile-menu">
      <div class="px-2 py-3 space-y-1 sm:px-3">

        <button [routerLink]="['/events', eventId]" [ngClass]="setClassesMobile(checkIfDashboard())">Dashboard</button>

        <button *ngFor="let page of pages;" [routerLink]="['/events', eventId, page.id]"
          [ngClass]="setClassesMobile(page.id === url)">{{page.name}}</button>


      </div>

      <div class="mt-3 px-2 space-y-1">
        <button [routerLink]="['/events', eventId, 'settings']"
          class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">Settings</button>

          <button (click)="logout()" class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800" role="menuitem" tabindex="-1"
          id="user-menu-item-2">Sign out</button>
      </div>

    </div>
  </nav>

  <header class="py-10">
    <div class="md:flex md:items-center md:justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-1 min-w-0">
        <!-- Profile -->
        <div class="flex items-center">
          <div>
            <div class="flex items-center">

              <h1 class="text-3xl font-bold text-white">
                {{title.title}}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
        <button *ngIf="hasThirdButton['value']" (click)="clickThirdButton()" type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          {{hasThirdButton['thirdButtonText']}}
        </button>
        <button *ngIf="hasSecondButton['value']" (click)="clickSecondButton()" type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          {{hasSecondButton['secondButtonText']}}
        </button>
        <button *ngIf="hasButton['value']" (click)="clickButton()" type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          {{hasButton['buttonText']}}
        </button>
      </div>
    </div>
  </header>
</div>