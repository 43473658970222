<div class="bg-gray-100">
  <app-main [title]="{title: 'Entries'}"></app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->
      <div class="bg-white rounded-lg shadow py-6">
        <div>
          <div class="sm:block">
            <div class="border-b border-gray-200">
              <div class="px-5 sm: px-6">
                <div class="grid grid-cols-3 gap-8">
                  <div class="col-span-2">
                    <nav class="flex space-x-4">
                      <!-- <a (click)="switchView(0)"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        [ngClass]="view == '0' ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500'">
                        Credit/Debit
                      </a> -->

                      <a (click)="switchView(1)"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        [ngClass]="view == '1' ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500'">
                        Submitted
                      </a>

                      <a (click)="switchView(3)"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        [ngClass]="view == '3' ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500'">
                        Marked Paid
                      </a>

                      <a (click)="switchView(2)"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        [ngClass]="view == '2' ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500'">
                        All
                      </a>
                    </nav>
                  </div>
                  <div>
                    <div class="min-w-0 flex-1 px-12">
                      <div class="mx-auto w-full max-w-xs">
                        <label for="desktop-search" class="sr-only">Search</label>
                        <div class="relative text-gray-400 focus-within:text-gray-600">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <!-- Heroicon name: mini/magnifying-glass -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                              fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd"
                                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                clip-rule="evenodd" />
                            </svg>
                          </div>
                          <input
                            class="block w-full rounded-md border border-gray-200 bg-white py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600 sm:text-sm"
                            placeholder="Search" type="search" name="search" [(ngModel)]="searchField">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="view == 0" class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Credit/Debit entries
          </h3>
          <p class="mt-1 text-sm text-gray-500">{{successfulEntries.length | number}} entries</p>
        </div>

        <div *ngIf="view == 1" class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Submitted entries
              </h3>
              <p class="mt-1 text-sm text-gray-500">{{failedEntries.length | number}} entries</p>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">


            </div>
          </div>
        </div>


        <div *ngIf="view == 2" class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                All Entries
              </h3>
              <p class="mt-1 text-sm text-gray-500">{{entries.length | number}} entries</p>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
        
            </div>
          </div>
        </div>



        <div *ngIf="view == 0" class="mt-4">

          <div>

            <ul role="list" class="divide-y divide-gray-200">
              <li *ngFor="let item of successfulEntries | filter : searchField">
                <div [routerLink]="['/events', eventId, 'entries', item['_id']]" class="block hover:bg-gray-50">
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">

                      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-emerald-600 truncate">{{item['name']}}</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">

                            <span class="truncate">{{item['phone']}} - {{item['state']}}</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-900">
                              Entry created on
                              <time>{{item['createdAt'] | date:'shortDate'}}</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                              <!-- Heroicon name: solid/check-circle -->
                              <svg *ngIf="item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>

                              <svg *ngIf="!item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>
                              Completed Payment
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>
            </ul>


          </div>
        </div>

        <div *ngIf="view == 1" class="mt-4">

          <div>

            <ul role="list" class="divide-y divide-gray-200">
              <li *ngFor="let item of failedEntries | filter:searchField">
                <div [routerLink]="['/events', eventId, 'entries', item['_id']]" class="block hover:bg-gray-50">
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">

                      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-emerald-600 truncate">{{item['name']}}</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">

                            <span class="truncate">{{item['phone']}} - {{item['state']}}</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-900">
                              Entry created on
                              <time>{{item['createdAt'] | date:'shortDate'}}</time>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>

        <div *ngIf="view == 2" class="mt-4">

          <div>

            <ul role="list" class="divide-y divide-gray-200">
              <li *ngFor="let item of entries | filter:searchField">
                <div [routerLink]="['/events', eventId, 'entries', item['_id']]" class="block hover:bg-gray-50">
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">

                      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-emerald-600 truncate">{{item['name']}}</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">

                            <span class="truncate">{{item['phone']}} - {{item['address']}} {{item['city']}}
                              {{item['state']}} {{item['zip']}}</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-900">
                              Entry created on
                              <time>{{item['createdAt'] | date:'shortDate'}}</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                              <!-- Heroicon name: solid/check-circle -->
                              <svg *ngIf="item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>

                              <svg *ngIf="!item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>
                              Completed Payment
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>

        <div *ngIf="view == 3" class="mt-4">

          <div>

            <ul role="list" class="divide-y divide-gray-200">
              <li *ngFor="let item of paymentCollectedEntries | filter : searchField">
                <div [routerLink]="['/events', eventId, 'entries', item['_id']]" class="block hover:bg-gray-50">
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">

                      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-emerald-600 truncate">{{item['name']}}</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">

                            <span class="truncate">{{item['phone']}} - {{item['state']}}</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-900">
                              Entry created on
                              <time>{{item['createdAt'] | date:'shortDate'}}</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                              <!-- Heroicon name: solid/check-circle -->
                              <svg *ngIf="item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>

                              <svg *ngIf="!item['paid']" class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd" />
                              </svg>
                              Completed Payment
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>
            </ul>


          </div>
        </div>


      </div>
    </div>

  </main>

</div>