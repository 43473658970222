<div class="bg-gray-100">
  <app-main [title]="{title: entryData['name']}"></app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">



      <div
        class="mx-auto grid max-w-full grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <!-- Invoice summary -->
        <div class="lg:col-start-3 lg:row-end-1">
          <h2 class="sr-only">Payment Info</h2>
          <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">

            <div class="relative flex items-start pt-6 px-6">
              <div class="min-w-0 flex-1 text-sm">
                <label for="paymentCollected" class="text-sm font-semibold leading-6 text-gray-900">Payment Collected</label>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input  [(ngModel)]="entryData['markedPaid']" (ngModelChange)="markPaid()" id="paymentCollected" name="paymentCollected" type="checkbox"
                  class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
              </div>
            </div>

            <dl class="flex flex-wrap py-6">
        
              <div class="flex-auto pl-6">
                <dt class="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
                <dd class="mt-1 text-base font-semibold leading-6 text-gray-900">{{entryData['amountPaid'] / 100 |
                  currency}}</dd>
              </div>
              <div class="flex-none self-end px-6 pt-4">
                <dt class="sr-only">Status</dt>
                <dd *ngIf="entryData['paid']"
                  class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                  Payment Collected</dd>
                <dd *ngIf="!entryData['paid']"
                  class="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20">
                  Payment not collected</dd>
              </div>
              <div *ngIf="entryData['paid']" class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Status</span>
                  <svg class="h-6 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                      clip-rule="evenodd" />
                  </svg>
                </dt>
                <dd class="text-sm leading-6 text-gray-500">Paid with {{entryData['paymentId']['brand']}}</dd>
              </div>
              <div *ngIf="entryData['paid']" class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Last 4</span>
                  <svg class="h-6 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                      clip-rule="evenodd" />
                  </svg>
                </dt>
                <dd class="text-sm leading-6 text-gray-500">Last4: {{entryData['paymentId']['last4']}}</dd>
              </div>
            </dl>
            <!-- <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
              <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Download receipt <span
                  aria-hidden="true">&rarr;</span></a>
            </div> -->
          </div>
        </div>

        <!-- Invoice -->
        <div
          class="bg-white -mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
          <div class="bg-white">
            <div class="-mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
              <div class="mt-4">
                <div class="flex items-center">

                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Entry
                    </h3>
                  </div>
                </div>
              </div>
              <div class="ml-4 mt-4 flex-shrink-0 flex">
                <!-- <button [routerLink]="['/edit-entry', entryData['_id']]" type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                  <span>
                    Edit
                  </span>
                </button>
   -->
                <button (click)="toggleModal('deleteEntryModal')" type="button"
                  class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <!-- Heroicon name: solid/mail -->

                  <span>
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </div>
          <dl class="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
            <div class="sm:pr-4">
              <dt class="inline text-gray-500">Submitted on </dt>
              <dd class="inline text-gray-700">{{entryData['createdAt'] | date}}</dd>
            </div>
            <div class="mt-2 sm:mt-0 sm:pl-4">
              <dt class="inline text-gray-500">Address: </dt>
              <dd class="inline text-gray-700">{{entryData['address']}} {{entryData['state']}}, {{entryData['zip']}}
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
              <dt class="inline text-gray-500">Phone: </dt>
              <dd class="inline text-gray-700">{{entryData['phone']}}</dd>
            </div>
            <div class="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
              <dt class="inline text-gray-500">Email: </dt>
              <dd class="inline text-gray-700">{{entryData['email']}}</dd>
            </div>
          </dl>
          <div class="mt-16">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Classes
              </h3>
            </div>
            <div *ngFor="let class of classArr;">
              <div *ngIf="groupedEntriesByClass[class['_id']]" class="mt-4 space-y-4">
                <div class="rounded-lg border border-gray-200 shadow-sm p-4">

                  <div class="text-md text-gray-700">
                    {{class['name']}}
                  </div>

                  <div class="mt-4 mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 lg:gap-8">
                    <div *ngFor="let entry of groupedEntriesByClass[class['_id']];">
                      <div class="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm p-4">


                        <div class="px-4 py-2">
                          <div class="flex items-center justify-between">
                            <dt class="text-sm text-gray-600">{{entry.name}}</dt>
                            <dd class="text-sm font-medium text-gray-900">{{class.multipleGos ? "Multiple Go's" :
                              entry.date | moment:'dddd'}}</dd>
                          </div>
                        </div>

                        <div *ngIf="entry?.rollovers" class="px-4 py-2">
                          <div *ngFor="let rollover of entry.rollovers;" class="flex items-center justify-between">
                            <dt class="text-sm text-gray-600">Rollover to</dt>
                            <dd class="text-sm font-medium text-gray-900">{{rollover['rollTo']['name']}} {{rollover['date'] | date: 'EEEE'}}</dd>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <table *ngIf="groupedSidePotsByClass[class['_id']]"
                    class="mt-4 w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                      <col class="w-full">
                      <col>
                    </colgroup>
                    <thead class="border-b border-gray-200 text-gray-900">
                      <tr>
                        <th scope="col" class="px-0 py-3 font-semibold">Sidepot</th>
                        <th scope="col" class="py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">Horse</th>
                        <th scope="col" class="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sidepot of groupedSidePotsByClass[class['_id']];"
                        class="border-b border-gray-100">
                        <td class="max-w-0 px-0 py-5 align-top">
                          <div class="truncate font-medium text-gray-900">{{sidepot['sidePot'].name}}</div>
                        </td>
                        <td class="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">{{sidepot.name}}</td>
                        <td class="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">{{sidepot.date |
                          moment:'dddd'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <table *ngIf="entryData['additionalItems'].length"
            class="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
            <colgroup>
              <col class="w-full">
              <col>
            </colgroup>
            <thead class="border-b border-gray-200 text-gray-900">
              <tr>
                <th scope="col" class="px-0 py-3 font-semibold">Item</th>
                <th scope="col" class="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of entryData['additionalItems'];" class="border-b border-gray-100">
                <td class="max-w-0 px-0 py-5 align-top">
                  <div class="truncate font-medium text-gray-900">{{item['item']?.name}}</div>
                </td>
                <td class="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">{{item.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>


    </div>

  </main>


</div>


<div *ngIf="modalViews['deleteEntryModal']" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div
      class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <!-- Heroicon name: outline/exclamation -->
          <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Delete this entry
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Once you delete this entry, you will lose all data associated with it.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button (click)="delete(entryId)" type="button"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Delete
        </button>
        <button type="button" (click)="toggleModal('deleteEntryModal')"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>