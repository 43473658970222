import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-class-entries',
  templateUrl: './class-entries.component.html',
  styleUrls: ['./class-entries.component.scss']
})
export class ClassEntriesComponent implements OnInit {

  eventId: any;
  classId: any;
  eventData: any = {};
  classData: any = {};
  entries: any = [];

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  

  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.classId = routeParams.get('classid');

    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;

        this.event.getOneClasses(this.classId).subscribe(
          response => {

            this.classData = response;
            console.log(this.classData);

            this.entry.class(this.classId).subscribe(
              response => {
                this.entries = response;
                console.log(response);
              },
              err => console.log(err)
            );

          },
          err => console.log(err)
        );




      },
      err => console.log(err)
    );


  }

  exportToCSV() {

    var str = '';
    var header = "Draw,Rider,Horse,Rollover,District,Member #,BBR Member ID,Time,Notes";

    //Add header to output str
    str += header;
    str += '\n';

    for (var i = 0; i < this.entries.length; i++) {
      var line = '';
      if (line != '') line += ','

      line += "";
     


      line += ',';
      if (this.entries[i]['entry']?.name) {
        line += this.entries[i]['entry']['name'].replace(/,/g, '');
      } else {
        line += "";
      }

      line += ',';
      if (this.entries[i]['name']) {
        line += this.entries[i]['name'].replace(/,/g, '');
      } else {
        line += "";
      }


      line += ',';
      if (this.entries[i]['rollover']) {
        line += this.entries[i]['rollover']['rollTo']['name'];
      } else {
        line += "";
      }


      line += ','
      if (this.entries[i]['entry']?.district) {
        line += this.entries[i]['entry']['district'].replace(/,/g, '');
      } else {
        line += "";
      }

   
      line += ','
      if (this.entries[i]['entry']?.memberNumber) {
        line += this.entries[i]['entry']['memberNumber'];
      } else {
        line += "";
      }

      if (this.entries[i]['bbrMemberNumber']) {
        line += this.entries[i]['bbrMemberNumber'].replace(/,/g, '');
      } else {
        line += "";
      }
 
      line += "";
      line += ',';


      if (this.entries[i]['notes']) {
        line += this.entries[i]['notes'].replace(/,/g, '');
      } else {
        line += "";
      }

      str += line + '\n';
    }



    // const csvArray = header.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.classData['name'] + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


}
