<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <img class="mx-auto h-24 w-auto" src="../../../assets/images/logo_block_green.png" alt="myentryform">
      <div class="text-center">
        <!-- <span class="text-center antialiased text-gray-800 font-extrabold text-3xl">BarrelBook</span> -->
        <h2 class="mt-6 text-lg font-bold text-gray-900">
          Reset Your Password
        </h2>
        <p class="mt-2 text-sm leading-6 text-gray-500">
            You will receive a secure email containing a temporary password. Upon first login, you will be prompted to create a new, permanent password of your choosing.
          </p>
      </div>
      <form class="mt-8 space-y-6" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
            <label for="email-address" class="block text-sm font-medium leading-6 text-gray-500">Email address</label>
            <input id="email-address" formControlName="email" name="email" type="email" required
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
              placeholder="john@example.com">
          <div
            *ngIf="!resetForm.get('email')?.valid && (resetForm.get('email')?.dirty ||resetForm.get('email')?.touched)">
            <p [hidden]="!resetForm.get('email').errors?.required" class="mt-2 text-sm text-red-600"
              id="email-error">Email is required</p>
            <p [hidden]="!resetForm.get('email').errors?.pattern" class="mt-2 text-sm text-red-600" id="email-error">
              Email must be valid</p>
          </div>
        </div>
  
        <div>
            <button [disabled]="resetForm.invalid"  [ngClass]="resetForm.invalid == false ? 'bg-emerald-600 hover:bg-emerald-700' : 'bg-gray-400 hover:bg-emerald-500'" type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-emerald-500 group-hover:text-emerald-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>