import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';

import * as moment from 'moment';

@Component({
  selector: 'app-create-fee',
  templateUrl: './create-fee.component.html',
  styleUrls: ['./create-fee.component.scss']
})
export class CreateFeeComponent implements OnInit {

  eventId: any;
  createFeeForm: FormGroup;
  eventData: any = {};
  additionalItems: any[] = [];

  dateTimeconfig: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY'
  }


  constructor(private _formBuilder: FormBuilder, public event: EventService,  private route: ActivatedRoute, private router: Router) {
    this.createFeeForm = this._formBuilder.group({
      name: ['', Validators.required],
      price: Number,
      quantity: Number,
      percent: Boolean,
      perHorse: Boolean,
      perDay: Boolean,
      date: String,
      removedBy: String
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');


    this.event.getOne(this.eventId).subscribe(
      response => {

        this.eventData = response;
        console.log(this.eventData);
      },
      err => console.log(err)
    );

    this.event.getAdditionalItems(this.eventId).subscribe(data => {
      this.additionalItems = data;
    })
  }


createFee() {
  
    var data = {
      "event": this.eventId,
      "name": this.createFeeForm.value['name'],
      "price": this.createFeeForm.value['price'],
      "percent": this.createFeeForm.value['percent'],
      "perHorse": this.createFeeForm.value['perHorse'],
      "perDay": this.createFeeForm.value['perDay'],
      "removedBy": this.createFeeForm.value['removedBy'],
      "date": this.createFeeForm.value['date']
    }

    console.log(data);

    this.event.createFee(data).subscribe(
      response => {

        this.router.navigate(['events', this.eventId]);

      },
      err => console.log(err)
    );
    
  }

  cancel() {
    this.router.navigate(['events', this.eventId]);
  }

}
