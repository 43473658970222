<div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-6">
  <div class="space-y-8 divide-y divide-gray-200">
    <form (ngSubmit)="createFee()" [formGroup]="createFeeForm">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Create Fee
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Users will automatically be charged when submitting entry forms.
            </p>
          </div>
          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input type="text" name="name" id="name" placeholder="Youth 3D" formControlName="name"
                  class="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>

            <div class="relative flex items-start mt-2 py-4 ">
              <div class="min-w-0 flex-1 text-sm">
                <label for="perHorse" class="font-medium text-gray-700 select-none">Per Horse</label>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input formControlName="perHorse" id="perHorse" name="perHorse" type="checkbox"
                  class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="price" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Price
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input type="number" name="price" id="price" placeholder="0" formControlName="price"
                  class="block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="openingDateTime" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">

                <input [mode]="'daytime'" type="text" autocomplete="off" name="openingDateTime"
                  id="openingDateTime"
                  class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  [dpDayPicker]="dateTimeconfig" formControlName="date">

              </div>
            </div>

            <div>
              <label for="removedBy" class="block text-sm font-medium leading-6 text-gray-900">Remove this fee when this additional item is purchased</label>
              <select id="removedBy" formControlName="removedBy" name="removedBy" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-emerald-600 sm:text-sm sm:leading-6">
                <option *ngFor="let item of additionalItems" value="{{item._id}}">{{item.name}}</option>
              </select>
            </div>
            


          </div>

        </div>
      </div>

    </form>


    <div class="pt-5">
      <div class="flex justify-end">
        <button [routerLink]="['/events', eventId]" type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          Cancel
        </button>
        <button (click)="createFee()"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
          Save
        </button>
      </div>
    </div>
  </div>
</div>