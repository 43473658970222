<div class="bg-gray-100">
  <app-main [title]="{title: 'Draw'}" [hasButton]="{value: false, buttonText: 'Shuffle'}" (buttonClicked)="shuffle()">
  </app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">

      <div class="mb-6">
        <div class="sm:block bg-white rounded-lg shadow">
          <div class="px-4 py-5 m:px-6">
            <div class="px-5 sm: px-6">

              <nav class="-mb-px flex space-x-8 bg-white" aria-label="Tabs">
                <!-- Current: "border-emerald-500 text-emerald-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                <a (click)="switchView(0)"
                  class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  [ngClass]="view == '0' ? 'border-emerald-500 text-grey-600' : 'border-transparent text-gray-500'">
                  Pending
                </a>

                <a (click)="switchView(1)"
                  class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  [ngClass]="view == '1' ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500'">
                  Completed
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="view === 0">
        <div
          class="bg-emerald-600 sticky top-0 z-50 rounded-t px-5 py-2 sm:px-6 sm:flex sm:items-center sm:justify-between">

          <li (click)="toggleModal('seperatorModal')" class="col-span-1 flex">
            <div
              class="flex-shrink-0 flex items-center justify-center w-auto px-4 bg-gray-100 text-gray-400 text-2xl font-medium rounded-l-md">
              Separator
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <div class="text-gray-900 text-xl font-medium hover:text-gray-600">
                  {{seperator}}</div>
                <!-- <p class="text-gray-500 text-lg">{{classData['runOrder'][inputIndex].name}}</p> -->
              </div>
            </div>
          </li>

          <div class="mt-3 sm:mt-0 sm:ml-4">
            <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button (click)="saveOrder()" type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-700 hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                Save
              </button>

              <button (click)="shuffle()" type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-700 hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                Shuffle
              </button>
            </div>
          </div>
        </div>



        <div class="bg-white rounded-b shadow px-5 py-6 sm:px-6">

          <table cdkDropList class="table-list" (cdkDropListDropped)="drop($event)">
            <tbody class="table-box" *ngFor="let item of entries;let indexOfelement=index;" cdkDrag>
              <div class="table-placeholder" *cdkDragPlaceholder></div>
              <tr class="w-full">
                <td class="px-6 whitespace-nowrap w-full">
                  <div class="flex items-center">
                    <div class="px-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{indexOfelement + 1}}</div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{item['entry']?.name}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 whitespace-nowrap">
                  <div class="text-sm text-gray-900">{{item?.name}}</div>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div *ngIf="view === 1">

        <div
          class="bg-emerald-600 sticky top-0 z-50 rounded-t px-5 py-2 sm:px-6 sm:flex sm:items-center sm:justify-between">

          <div class="mt-3 sm:mt-0">
            <div class="mt-6 flex space-x-3 md:mt-0">
              <button (click)="resetOrder()" type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-700 hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                Reset
              </button>
            </div>
          </div>
        </div>


        <div class="bg-white rounded shadow px-5 py-6 sm:px-6">

          <table class="table-list">
            <tbody class="table-box" *ngFor="let item of completed;let indexOfelement=index;">
              <tr class="w-full">
                <td class="px-6 whitespace-nowrap w-full">
                  <div class="flex items-center">
                    <div class="px-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{indexOfelement + 1}}</div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{item['entry']?.name}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 whitespace-nowrap">
                  <div class="text-sm text-gray-900">{{item?.name}}</div>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </main>
</div>


<div *ngIf="modalViews['seperatorModal']" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div
      class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="max-w-lg mx-auto">
        <h2 class="text-lg font-medium text-gray-900">Change default seperator range!</h2>
        <div>
          <label for="seperator" class="sr-only">Seperator Length</label>
          <input [(ngModel)]="seperator" type="number" name="seperator" id="seperator"
            class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="10">
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button type="button" (click)="toggleModal('seperatorModal')"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>