<div class="bg-gray-100">
  <app-main [title]="{title: 'Classes'}"></app-main>



  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->

      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Open</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let item of classArr;">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{item['name']}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a [routerLink]="['/events/class', eventId, item['_id']]" class="ml-2 text-emerald-600 hover:text-emerald-900">Open</a>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>


  </div>
</main>