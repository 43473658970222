<div class="bg-gray-100">
  <app-main [title]="{title: 'Settings'}"></app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->
      <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">


            <form (ngSubmit)="onSubmit()" [formGroup]="eventForm" class="space-y-8 divide-y divide-gray-200">
              <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Event Information
                  </h3>
                </div>

                <div class="pt-5">
                  <div class="flex justify-end">
                    <button (click)="duplicateEvent()"
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                      Duplicate
                    </button>
                    <button (click)="delete()"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Delete
                  </button>
                  </div>
                </div>

                <div class="space-y-6 sm:space-y-5">
                  Entries
                  <div class="relative flex items-start mt-2 py-4 ">
                    <div class="min-w-0 flex-1 text-sm">
                      <label for="complete" class="font-medium text-gray-700 select-none">Complete</label>
                    </div>
                    <div class="ml-3 flex items-center h-5">
                      <input formControlName="complete" id="complete" name="complete" type="checkbox"
                        class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
                    </div>
                  </div>
          
                  <div class="relative flex items-start mt-2 py-4 ">
                    <div class="min-w-0 flex-1 text-sm">
                      <label for="bbrBarrel" class="font-medium text-gray-700 select-none">BBR Barrel Event</label>
                    </div>
                    <div class="ml-3 flex items-center h-5">
                      <input formControlName="bbrBarrel" id="bbrBarrel" name="bbrBarrel" type="checkbox"
                        class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
                    </div>
                  </div>

                  <div class="relative flex items-start mt-2 py-4 ">
                    <div class="min-w-0 flex-1 text-sm">
                      <label for="rodeo" class="font-medium text-gray-700 select-none">Event is Rodeo</label>
                    </div>
                    <div class="ml-3 flex items-center h-5">
                      <input formControlName="rodeo" id="rodeo" name="rodeo" type="checkbox"
                        class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
                    </div>
                  </div>


                  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Address
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input formControlName="address" type="text" name="address" id="address"
                        class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    </div>
                  </div>

                  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="openingDateTime" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Opening Entry Date
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">

                      <input [mode]="'daytime'" type="text" autocomplete="off" name="openingDateTime"
                        id="openingDateTime"
                        class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        [dpDayPicker]="dateTimeconfig" formControlName="openingDateTime">

                    </div>
                  </div>


                  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="closingDateTime" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Closing Entry Date
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">

                      <input [mode]="'daytime'" type="text" autocomplete="off" name="closingDateTime"
                        id="closingDateTime"
                        class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        [dpDayPicker]="dateTimeconfig" formControlName="closingDateTime">

                    </div>
                  </div>
                  
                </div>
              </div>

              <div class="pt-5">
                <div class="flex justify-end">
                  <button type="button" [routerLink]="['/events', eventId]"
                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    Cancel
                  </button>
                  <button type="submit"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </form>









          </div>

        </div>
      </div>
    </div>
  </main>

</div>
