import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit {

  eventId: any;
  classArr: any = [];

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }
  
  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getClasses(this.eventId).subscribe(
      response => {
        console.log(response);
        this.classArr = response;
      },
      err => console.log(err)
    );
  }

}
