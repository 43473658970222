import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { SuperuserHeaderComponent } from './components/superuser-header/superuser-header.component';
import { HeaderRoutingModule } from './header.routing.module';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';


@NgModule({
  declarations: [
    MainComponent,
    SuperuserHeaderComponent,
    AdminHeaderComponent
  ],
  imports: [
    CommonModule,
    HeaderRoutingModule
  ],
  exports: [
    MainComponent,
    SuperuserHeaderComponent,
    AdminHeaderComponent
  ],
  providers: []
})
export class HeaderModule { }
