import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {

  eventId: any;
  classId: any;
  classData: any = {};
  entries: any = [];


  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.classId = routeParams.get('classid');
    this.eventId = routeParams.get('id');

    this.event.getOneClasses(this.classId).subscribe(
      response => {

        this.classData = response;
        
        this.entry.class(this.classId).subscribe(
          response => {
            this.entries = response;
            console.log(response);
          },
          err => console.log(err)
        );
      },
      err => console.log(err)
    );
    
   

  }

}
