import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-additional-items',
  templateUrl: './additional-items.component.html',
  styleUrls: ['./additional-items.component.scss']
})
export class AdditionalItemsComponent implements OnInit {


  eventId: any;
  additionalItems: any = [];

  viewSideMenu: any = false;

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  toggleModal(modalName: any) {
    this.modalViews[modalName] = !this.modalViews[modalName];
  }

  openModal(modalName: any) {
    this.modalViews[modalName] = true;
  }

  editItem(id: any) {

    this.event.getOneAdditionalItem(id).subscribe(
      response => {
        this.editData = response;
        console.log(this.editData);
        this.modalViews['editItemModal'] = true;
      },
      err => console.log(err)
    );

  }

  deleteItem() {

    this.event.deleteOneAdditionalItem(this.editData['_id']).subscribe(
      response => {
        this.event.getAdditionalItems(this.eventId).subscribe(
          response => {
            this.additionalItems = response;
            this.modalViews['editItemModal'] = false;
          },
          err => console.log(err)
        );
      },
      err => console.log(err)
    );

  }

  submitEdit() {
    console.log(this.editData);

    this.event.editOneAdditionalItem(this.editData).subscribe(
      response => {

        this.event.getAdditionalItems(this.eventId).subscribe(
          response => {
            this.additionalItems = response;
            this.modalViews['editItemModal'] = false;
          },
          err => console.log(err)
        );

      },
      err => console.log(err)
    );
  }

  editData: any = {};
  modalViews: any = {
    "editItemModal": false
  };




  constructor(private excelService: ExcelService, private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getAdditionalItems(this.eventId).subscribe(
      response => {
        this.additionalItems = response;
        console.log(response);
      },
      err => console.log(err)
    );
  }


}
