<div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-6">
<form (ngSubmit)="onSubmit()" [formGroup]="createOrgForm" class="space-y-8 divide-y divide-gray-200">
  <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
    <div>

      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Organization Information
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          You can create users in this organization after it is create.
        </p>
      </div>
      <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input type="text" name="name" id="name" placeholder="20** Rodeo" formControlName="name"
              class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Address
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input type="text" name="address" id="address" placeholder="Ex. 1 Main St, New York, NY" formControlName="address"
              class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>

        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="phone" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Phone
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input type="text" name="phone" id="phone" placeholder="(XXX) XXX-XXXX" formControlName="phone"
              class="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>

      </div>


    </div>
  </div>
  
  <div class="pt-5">
    <div class="flex justify-end">
      <button [routerLink]="'/superuser'" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
        Cancel
      </button>
      <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
        Save
      </button>
    </div>
  </div>

</form>
</div>