import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';

import { LoginComponent } from './components/login/login.component';
import { EventComponent } from './components/event/event.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventService } from './core/services/event.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './core/helpers';
import { EventsComponent } from './components/events/events.component';
import { CommonModule } from '@angular/common';
import { DpDatePickerModule } from 'ng2-date-picker';
import { EntriesComponent } from './components/entries/entries.component';
import { ClassComponent } from './components/class/class.component';
import { ClassEntriesComponent } from './components/class-entries/class-entries.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SidepotEntriesComponent } from './components/sidepot-entries/sidepot-entries.component';
import { ItemsComponent } from './components/items/items.component';
import { NotificationsModule } from '@pascaliske/ngx-notifications';
import { EntryComponent } from './components/entry/entry.component';
import { ClassesComponent } from './components/classes/classes.component';
import { SidepotsComponent } from './components/sidepots/sidepots.component';
import { AdditionalItemsComponent } from './components/additional-items/additional-items.component';
import { FeesComponent } from './components/fees/fees.component';
import { SettingsComponent } from './components/settings/settings.component'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderComponent } from './components/order/order.component';
import { ExcelService } from './core/services/excel.service';
import { CreateFeeComponent } from './components/create-fee/create-fee.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ShowtimeComponent } from './components/showtime/showtime.component';
import { showtimeSocketService } from './core/services/showtimeSocket.service';
import { SuperuserComponent } from './components/superuser/superuser.component';
import { CreateOrgComponent } from './components/create-org/create-org.component';
import { ContestantsComponent } from './components/contestants/contestants.component';
import { IncomeChargesModule } from './features/income-charges/income-charges.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { HeaderModule } from './features/header/header.module';
import { MomentPipe } from './core/pipes/moment/moment';
import { NotesComponent } from './components/notes/notes.component';
import { AgGridModule } from 'ag-grid-angular';
import { SuperuserEventsComponent } from './components/superuser-events/superuser-events.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { ReportsComponent } from './components/reports/reports.component';

// const config: SocketIoConfig = {
//   url: 'https://api.myentryform.com', options: {
//     transports: ['websocket']
//   }
// };


@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    LoginComponent,
    EventsComponent,
    EventComponent,
    EntriesComponent,
    ClassComponent,
    ClassEntriesComponent,
    SidepotEntriesComponent,
    ItemsComponent,
    EntryComponent,
    ClassesComponent,
    SidepotsComponent,
    AdditionalItemsComponent,
    FeesComponent,
    SettingsComponent,
    OrderComponent,
    CreateFeeComponent,
    ShowtimeComponent,
    SuperuserComponent,
    CreateOrgComponent,
    ContestantsComponent,
    MomentPipe,
    NotesComponent,
    SuperuserEventsComponent,
    RegisterComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    ReportsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    A11yModule,
    CdkTableModule,
    DragDropModule,
    AgGridModule,
    DpDatePickerModule,
    NgxDropzoneModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    IncomeChargesModule,
    HeaderModule,
    NotificationsModule.forRoot(),
    CreditCardDirectivesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    EventService,
    ExcelService,
    showtimeSocketService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
