<div class="bg-gray-100">
  <app-main [title]="{title: 'Fees'}"  [hasButton]="{value: true, buttonText: 'Create Fee'}"
  (buttonClicked)="createFee()"></app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->
      <div class="bg-white rounded-lg px-5 py-6 sm:px-6">
   
        <ul class="divide-y divide-gray-200">
          <li *ngFor="let item of feeArr;">
            <div class="block hover:bg-gray-50">
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">

                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p class="text-sm font-medium text-emerald-600 truncate">{{item['name']}}</p>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <p class="text-sm text-gray-900">
                          <span *ngIf="!item.percent">$</span>{{item.price}}<span *ngIf="item.percent">%</span>
                        </p>
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <button (click)="deleteFee(item['_id'])" class="text-sm font-medium text-emerald-600 truncate">Delete</button>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- Heroicon name: solid/chevron-right -->
                  <!-- <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd" />
            </svg> -->
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </main>
</div>
