import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdditionalItemsComponent } from './components/additional-items/additional-items.component';
import { ClassEntriesComponent } from './components/class-entries/class-entries.component';
import { ClassesComponent } from './components/classes/classes.component';
import { CreateFeeComponent } from './components/create-fee/create-fee.component';
import { OrderComponent } from './components/order/order.component';
import { EntriesComponent } from './components/entries/entries.component';
import { EntryComponent } from './components/entry/entry.component';
import { EventComponent } from './components/event/event.component';
import { EventsComponent } from './components/events/events.component';
import { FeesComponent } from './components/fees/fees.component';
import { ItemsComponent } from './components/items/items.component';
import { LoginComponent } from './components/login/login.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShowtimeComponent } from './components/showtime/showtime.component';
import { SidepotEntriesComponent } from './components/sidepot-entries/sidepot-entries.component';
import { SidepotsComponent } from './components/sidepots/sidepots.component';
import { SuperuserComponent } from './components/superuser/superuser.component';
import { CreateOrgComponent } from './components/create-org/create-org.component';
import { ContestantsComponent } from './components/contestants/contestants.component';
import { NotesComponent } from './components/notes/notes.component';
import { AuthGuard } from './core/guards';
import { SuperuserEventsComponent } from './components/superuser-events/superuser-events.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { ReportsComponent } from './components/reports/reports.component';

export const routeStateTrigger = trigger('routeState', [
  transition('* => *', [
      query(':enter', [
              style({ opacity: 0 })
          ], { optional: true }
      ),
      group([
          query(':leave', [
                  animate(300, style({ opacity: 0 }))
              ],
              { optional: true }
          ),
          query(':enter', [
                  style({ opacity: 0 }),
                  animate(300, style({ opacity: 1 }))
              ],
              { optional: true }
          )
      ])
  ])
]);

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-password',
    component: NewPasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'superuser/organizations',
    component: SuperuserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'superuser/contestants',
    component: ContestantsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'superuser/events',
    component: SuperuserEventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-org',
    component: CreateOrgComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'showtime/:id',
    component: ShowtimeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id',
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/notes',
    component: NotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/classes',
    component: ClassesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/sidepots',
    component: SidepotsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/entries',
    component: EntriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/additional-items',
    component: AdditionalItemsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/fees',
    component: FeesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/class/:id/:classid',
    component: ClassEntriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/sidepot/:id/:sidepotid',
    component: SidepotEntriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/items/:id/:itemid',
    component: ItemsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-fee/:id',
    component: CreateFeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order/:id/:classId/:date',
    component: OrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:id/entries/:entryId',
    component: EntryComponent,
    canActivate: [AuthGuard]
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
