<div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8  py-6 ">

    <main>
      <div class="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl">
        <!-- Page header -->
        <div class="mb-8 max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
          <div class="flex-1 min-w-0">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              All Entries
            </h2>
            <p class="text-sm font-medium text-gray-500">For {{classData['name']}}</p>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <button [routerLink]="['/events', eventId]" type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              Cancel
            </button>
          </div>
        </div>
  
  
  
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rider
                      </th>
                      <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Horse
                      </th>
  
                      <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Entry Date
                      </th>
  
                      <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
  
                    <tr *ngFor="let item of entries;" class="bg-white">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{item['entry']['name']}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{item['name']}}
                      </td>
            
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <time datetime="2020-09-20">{{item['entry']['createdAt'] | date:'shortDate'}}</time>
                      </td>
  
  
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a class="text-emerald-600 hover:text-emerald-900">Edit</a>
                      </td>
                    </tr>
  
  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
  
      </div>
  
  
    </main>
  </div>
  