import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'superuser-header',
  templateUrl: './superuser-header.component.html',
  styleUrls: ['./superuser-header.component.scss']
})
export class SuperuserHeaderComponent implements OnInit {
  @Input() title: any = { title: "" };
  @Input() hasButton: any = { value: false, buttonText: "" };
  @Output() buttonClicked = new EventEmitter();

  @Input() hasSecondButton: any = { value: false, secondButtonText: "" };
  @Output() secondButtonClicked = new EventEmitter();


  clickButton() { // You can give any function name

    this.buttonClicked.emit(true);
  }

  
  clickSecondButton() { // You can give any function name

    this.secondButtonClicked.emit(true);
  }
  
  
  pages: any = [
    {
      name: "Organizations",
      id: "organizations"
    },
    {
      name: "Events",
      id: "events"
    },
    {
      name: "Contestants",
      id: "contestants"
    }
  ];

  eventId: any;
  viewSideMenu: any = false;
  url: any = "";

  toggleSideMenu() {
    this.viewSideMenu = !this.viewSideMenu;
  }

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.url = this.router.url.split("/").pop();
    // console.log("url", this.url);
  }

  checkIfDashboard() {
    var result = true;

    for (let i = 0; i < this.pages.length; i++) {
      // console.log(this.url, this.pages[i]['id']);
      if (this.url === this.pages[i]['id']) {
        result = false;
      }
    }

    return result;
  }

  setClasses(active: Boolean) {
    let activeClasses = {
      'bg-emerald-900': true,
      'text-white': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-sm': true,
      'font-medium': true
    }

    let plainClasses = {
      'text-emerald-300': true,
      'hover:bg-emerald-800': true,
      'hover:text-white': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-sm': true,
      'font-medium': true
    }

    if (active) {
      return activeClasses;
    } else {
      return plainClasses;
    }

  }

  setClassesMobile(active: Boolean) {
    let activeClasses = {
      'bg-emerald-900': true,
      'text-white': true,
      'block': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-base': true,
      'font-medium': true,
      'w-full': true
    }

    let plainClasses = {
      'text-emerald-300': true,
      'hover:bg-emerald-700': true,
      'hover:text-white': true,
      'block': true,
      'px-3': true,
      'py-2': true,
      'rounded-md': true,
      'text-base': true,
      'font-medium': true,
      'w-full': true
    }

    if (active) {
      return activeClasses;
    } else {
      return plainClasses;
    }

  }

  openLink(pageId: string) {
    this.router.navigate(['superuser', pageId]);
  }
}
