import { Component, Input, OnInit } from '@angular/core';
import { IncomeService } from '../../services/income.service';

@Component({
  selector: 'credit-debit-balance',
  templateUrl: './credit-debit.component.html',
  styleUrls: ['./credit-debit.component.scss']
})
export class CreditDebitComponent implements OnInit {

  @Input() eventId: string = "";

  constructor(public income: IncomeService) { }

  ngOnInit(): void {
    this.income.getEventCreditIncome(this.eventId);
  }

}
