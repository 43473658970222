import { Component, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawService } from 'src/app/core/services/draw.service';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-showtime',
  templateUrl: './showtime.component.html',
  styleUrls: ['./showtime.component.scss']
})
export class ShowtimeComponent implements OnInit {

  eventId: any;
  classArr: any = [];

  constructor(private router: Router, private route: ActivatedRoute, private event: EventService, private entry: EntryService, private drawService: DrawService, private excelService: ExcelService) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getClasses(this.eventId).subscribe(
      response => {
        console.log(response);
        this.classArr = response;
      },
      err => console.log(err)
    );

  }

  classNamesById() {
    let result: any = {};
    for (let i = 0; i < this.classArr.length; i++) {
      result[this.classArr[i]['_id']] = this.classArr[i]['name'];
    }
    return result;
  }

  exportDrawsSpecificClass(classId: string) {
    this.drawService.exportDraw(this.eventId).subscribe(
      classDates => {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let classNameDate: any = [];
        let result: any = {};

        for (let i = 0; i < this.classArr.length; i++) {
          if (this.classArr[i]['_id'] === classId) {
          // Create object for specific nameDate
          // Also pull out class name and draw date
          // Add data to object for report
          //@ts-ignore
          for (let x = 0; x < classDates.length; x++) {
            if (classDates[x]['class']['_id'] == this.classArr[i]['_id']) {
              let drawDateId = classDates[x]['_id'];
              //@ts-ignore
              result[drawDateId] = [];
              if (this.classArr[i]['multipleGos']) {
                result[drawDateId + 1] = [];
              }

              let currentDate = classDates[x]['date'];
              let d = new Date(currentDate);
              let dayName = days[d.getDay() + 1];

              if (dayName === undefined) {
                dayName = 'Sunday'
              }

              for (let y = 0; y < classDates[x]['runOrder'].length; y++) {
                let contestant = classDates[x]['runOrder'][y];
                let entryObj = {
                    "Contestant": contestant['entry']['name'],
                    "Horse": contestant['name'],
                    "Rollover": contestant['rollover'] ? contestant['rollover']['label'] : '',
                    "District": "*" + contestant['entry']['district'],
                    "Member #": "*" + contestant['entry']['memberNumber'] ? contestant['entry']['memberNumber'] : "",
                    "Time": ""
                  
                }
                result[drawDateId].push(entryObj);
              }

              if (this.classArr[i]['multipleGos']) {
                for (var y = classDates[x]['runOrder'].length - 1; y >= 0; y--) {
                  let contestant = classDates[x]['runOrder'][y];
                  let entryObj = {
                    "Contestant": contestant['entry']['name'],
                    "Horse": contestant['name'],
                    "Rollover": contestant['rollover'] ? contestant['rollover']['label'] : '',
                    "District": "*" + contestant['entry']['district'],
                    "Member #": "*" + contestant['entry']['memberNumber'] ? contestant['entry']['memberNumber'] : "",
                    "Time": "",
                    "Avg": ""
                  }

                  result[drawDateId + 1].push(entryObj);

                }
              }

              //@ts-ignore
              if (this.classArr[i]['multipleGos']) {
                classNameDate.push(classDates[x]['class']['name'] + ' 1st');
                classNameDate.push(classDates[x]['class']['name'] + ' 2nd');
              } else {
                classNameDate.push(classDates[x]['class']['name'] + ' ' + dayName);
              }
            }
          }

          console.log('Work Sheet names', classNameDate);
          this.excelService.classDrawExport(classNameDate, Object.values(result), this.classArr[i]['name']);
        }
        }

      },
      err => console.log(err)
    );
  }

  exportDraws() {
    this.drawService.exportDraw(this.eventId).subscribe(
      classDates => {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let classNameDate: any = [];
        let result: any = {};
      

        let data = classDates.map((classItem: any) => classItem.runOrder.map((rollovers: any) => rollovers.rollovers.map((item: any) => ({
          "entryId": rollovers.entry['_id'],
          "horseName": rollovers.name,
          "label": item.label,
          "date": item.date,
          "class": item.class,
          "rollTo": item.rollTo
        }))));

        let allRollovers = [].concat(...data).filter((x: any) => {return x.length});
        let cleanedRollovers = [].concat(...allRollovers);

        console.log('data', cleanedRollovers);

        for (let i = 0; i < this.classArr.length; i++) {
          // Create object for specific nameDate
          // Also pull out class name and draw date
          // Add data to object for report
          //@ts-ignore
          for (let x = 0; x < classDates.length; x++) {

         
            if (classDates[x]['class']['_id'] == this.classArr[i]['_id']) {
              let drawDateId = classDates[x]['_id'];
              //@ts-ignore
              result[drawDateId] = [];
              // if (this.classArr[i]['multipleGos']) {
              //   result[drawDateId + 1] = [];
              // }

              let currentDate = classDates[x]['date'];
              let d = new Date(currentDate);
              let dayName = days[d.getDay()];

              if (dayName === undefined) {
                dayName = 'Sunday'
              }
         
              for (let y = 0; y < classDates[x]['runOrder'].length; y++) {
                let contestant = classDates[x]['runOrder'][y];
           
                let entryObj = {
                    "Contestant": contestant['entry']['name'],
                    "Horse": contestant['name'],
                    "Rollover": cleanedRollovers.filter((obj: any) => obj.class == this.classArr[i]['_id'] && obj.date == classDates[x]['date'] && obj.entryId == contestant['entry']['_id'] && obj.horseName == contestant['name']).map((rollover: any) => rollover.label).join(' | '),
                    "District": "*" + contestant['entry']['district'],
                    "Member #": "*" + contestant['entry']['memberNumber'] ? contestant['entry']['memberNumber'] : "",
                    "Time": ""
                  
                }
                result[drawDateId].push(entryObj);
              }

              // if (this.classArr[i]['multipleGos']) {
              //   for (var y = classDates[x]['runOrder'].length - 1; y >= 0; y--) {
              //     let contestant = classDates[x]['runOrder'][y];
              //     let entryObj = {
              //       "Contestant": contestant['entry']['name'],
              //       "Horse": contestant['name'],
              //       "Rollover": cleanedRollovers.filter((obj: any) => obj.rollTo == this.classArr[i]['_id'] && obj.date == classDates[x]['date'] && obj.entryId == contestant['entry']['_id'] && obj.horseName == contestant['name']).map((rollover: any) => rollover.label).join(' | '),
              //       "District": "*" + contestant['entry']['district'],
              //       "Member #": "*" + contestant['entry']['memberNumber'] ? contestant['entry']['memberNumber'] : "",
              //       "Time": "",
              //       // "Avg": ""
              //     }

              //     result[drawDateId + 1].push(entryObj);

              //   }
              // }

              //@ts-ignore
              // if (this.classArr[i]['multipleGos']) {
              //   classNameDate.push(classDates[x]['class']['name'] + ' 1st');
              //   classNameDate.push(classDates[x]['class']['name'] + ' 2nd');
              // } else {
                classNameDate.push(classDates[x]['class']['name']);
              // }
            }
          }
        }
        console.log('Work Sheet names', classNameDate);
        this.excelService.classDrawExport(classNameDate, Object.values(result), 'class_draws');

      },
      err => console.log(err)
    );

  }

}
