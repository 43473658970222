import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { User } from '../interfaces/user';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.auth.activeUser.getValue();

        if (currentUser) {
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            window.location.href = "https://admin.myentryform.com";
            return false;
        }

    }
}