import { Component, OnInit, Inject } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  
  eventsArr: any = [];

  view: any = 0;
  currentUser: any;
  private gridApi!: GridApi<any>;
  
  columnDefs: ColDef[] = [
    { colId: 'name', field: 'name', resizable: true, minWidth: 300, cellClass: 'bold'},
    { field: 'openingDateTime', headerName: 'Entries open', minWidth: 300,  cellRenderer: (data:any) => {
      return moment(data.value).format('MMMM Do YYYY, h:mm:ss a')
  }},
    { field: 'closingDateTime', headerName: 'Entries close', minWidth: 300,  cellRenderer: (data:any) => {
      return moment(data.value).format('MMMM Do YYYY, h:mm:ss a')
  }},
    { field: 'approved', headerName: 'Approved', minWidth: 200 },
    { field: 'complete', headerName: 'Completed', minWidth: 200 },
    
  ];

  gridOptions: any = {
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
      columnLimits: [
          {
              colId: 'name',
              minWidth: 300
          }
      ]
  },
  }

  constructor(private auth: AuthService, private router: Router, private event: EventService) { }

  ngOnInit(): void {
    this.auth.user.subscribe(x => (this.currentUser = x));

    this.event.getAllByCompany(this.currentUser['company']).subscribe(
      response => {
       this.event.events = response;
       this.eventsArr = this.event.events;
      },
      err => console.log(err)
    );
    
  }
  

  selectView(int: any) {
    this.view = int;
  } 


  openCreateEvent() {
    window.open("https://event.myentryform.com/event/" + this.currentUser['company'], "_blank");
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }


  onSelectionChanged($event: any) {
    const selectedRows = this.gridApi.getSelectedRows();
    console.log(selectedRows);
    this.router.navigate(['events', selectedRows[0]['_id']]);
  }

  
  onFilterTextBoxChanged($event: any) {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

}

