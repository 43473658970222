import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EntryService } from 'src/app/core/services/entry.service';

@Component({
  selector: 'app-contestants',
  templateUrl: './contestants.component.html',
  styleUrls: ['./contestants.component.scss']
})
export class ContestantsComponent implements OnInit {
  
  contestantArr: any = [];
  
  constructor(private auth: AuthService, private router: Router, private entry: EntryService) { }

  ngOnInit(): void {

    this.entry.uniqueNames().subscribe(
      response => {
       this.contestantArr = response;
      },
      err => console.log(err)
    );
    
  }

  totalEntries() {
    let total = 0;
    for (let i = 0; i < this.contestantArr.length; i++) {
      total += this.contestantArr[i].count;
    }
    return total;
  }

}

