import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EntryService {

  public states = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

  constructor(private http: HttpClient) { }

  add(data: any) {

    return this.http.post<Event[]>(environment.apiUrl + 'entry/', data);

  }

  edit(id:any, data: any) {

    return this.http.put<Event[]>(environment.apiUrl + 'entry/' + id, data);

  }


  markedPaid(id:any, data: any) {

    return this.http.put<Event[]>(environment.apiUrl + 'entry2/markedPaid/' + id, data);

  }

  refund(data: any) {

    return this.http.post<Event[]>(environment.apiUrl + 'entry/refund/', data);

  }

  recent(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/recent/' + id);

  }

  uniqueNames() {

    return this.http.get<any[]>(environment.apiUrl + 'entry/unique/names/');

  }

  getOne(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry2/' + id);

  }


  allSuccessful(id: any) {

    return this.http.get<any[]>(environment.apiUrl + 'entry/allSuccessful/' + id);

  }


  allFailed(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/allFailed/' + id);

  }

  allPaymentCollected(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry2/allPaymentCollected/' + id);

  }


  all(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/all/' + id);

  }

  getAllNotes(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'notes/all/' + id);

  }


  allPopClasses(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/allPopClasses/' + id);

  }

  allEntriesForEvent(id: any) {

    return this.http.get<any[]>(environment.apiUrl + 'entry2/getAllEntriesForEvent/' + id);

  }

  purchaseSummary(eventId: any) {

    return this.http.get<Blob>(environment.apiUrl + 'purchaseSummary/' + eventId, {
      responseType: 'blob' as 'json'
    });

  }

  class(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/class/' + id);

  }

  classByDate(id: any, date: string) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/class/date/' + id + '/' + date);

  }

  classByDateNoDraw(id: any, date: string) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/nodraw/class/date/' + id + '/' + date);

  }

  classByDateCompletedDraw(id: any, date: string) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/completedDraw/class/date/' + id + '/' + date);

  }


  sidepot(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/sidepot/' + id);

  }

  items(id: any) {

    return this.http.get<Event[]>(environment.apiUrl + 'entry/items/' + id);

  }

  itemsDeleteOne(id: any) {

    return this.http.delete<Event[]>(environment.apiUrl + 'entry/items/' + id);

  }


  getClassesByEntry(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'entry/classes/' + id);
  }

  getSidePotByEntry(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'entry/sidepots/' + id);
  }

  getAdditionalItemsByEntry(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'entry/additionalitems/' + id);
  }

  removeAll(id: any) {
    console.log("Removing", id);
    return this.http.delete<Event[]>(environment.apiUrl + 'entry/removeall/' + id);
  }

  getCheckoutPrice(data: any) {

    return this.http.post<Event[]>(environment.apiUrl + 'entry/checkoutPrice', data);

  }

  getMoneyCollected(id: any) {
    return this.http.get<{}>(environment.apiUrl + 'entry/moneyCollected/' + id);
  }

  getAllSidePotEntrys(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'entry/event/sidepotEntrys/' + id);
  }

  getMoneyDue(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'entry/moneyDue/' + id);
  }

  getMoneyDueBySidePot(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'entry/moneyDueBySidepot/' + id);
  }

  getAllAdditionalItemPurchases(id: any) {
    return this.http.get<Event[]>(environment.apiUrl + 'entry/event/additionalitems/' + id);
  }

  
  removeSidePotEntry(entryId: any, sidePotId:any, sidePots: any) {

    var data = {
      "sidePots": sidePots
    }

    return this.http.post<Event[]>(environment.apiUrl + 'entry/removeSidepot/' + entryId + "/" + sidePotId, data);

  }

  removesidepotentry(id: any) {
    return this.http.delete<[]>(environment.apiUrl + 'entry/removesidepotentry/' + id);
  }


}
