<div class="bg-gray-100">
  <app-main [title]="{title: 'Showtime'}" [hasButton]="{value: true, buttonText: 'Export Draws'}"
  (buttonClicked)="exportDraws()"></app-main>

  <main class="-mt-32">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <!-- Replace with your content -->

      <div class="grid grid-cols-1 gap-6">
        <div *ngFor="let class of classArr;">
        <div *ngIf="class['prices'].length > 0" class="bg-white rounded-lg shadow py-6">
          <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div class="md:flex md:items-center md:justify-between mx-auto">
              <div class="flex-1 min-w-0">
                <!-- Profile -->
                <div class="flex items-center">
                  <div>
                    <div class="flex items-center">
        
                      <h3 class="text-lg leading-6 font-medium text-gray-900">
                        {{class.name}}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <button (click)="exportDrawsSpecificClass(class._id)" type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                  Export Class Draws
                </button>
              </div>
            </div>
          </div>
          <ul class="divide-y divide-gray-200">
            <li *ngFor="let date of class['prices'];">
              <a  [routerLink]="['/order', eventId, class['_id'], date['date']]" class="block hover:bg-gray-50">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">

                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p class="text-sm font-medium text-emerald-600 truncate">{{date['date'] | date:'EEEE, MMMM d'}}</p>
                      </div>
                    </div>
                  </div>
                  <div>

                    <!-- <p *ngIf="date['runOrder'].length > 0" class="mt-2 flex items-center text-sm text-gray-500">
                      <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-emerald-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                      </svg>
                      Completed
                    </p> -->

                    <!-- Heroicon name: solid/chevron-right -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </a>
            </li>

          </ul>

        </div>
        </div>
      </div>
    </div>
  </main>
</div>
